/* eslint-disable */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import Nav from './AppNav/Nav'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import PerfectScrollbar from 'react-perfect-scrollbar'
import HeaderLogo from '../AppLogo/AppLogo'

import {setEnableMobileMenu} from '../../actions'

class AppSidebar extends Component {
    state = {};

    toggleMobileSidebar = () => {
      const { enableMobileMenu, setEnableMobileMenu } = this.props
      setEnableMobileMenu(!enableMobileMenu)
    }
    closeMobileSidebar = () => {
      const { setEnableMobileMenu } = this.props
      setEnableMobileMenu(false)
    }

    render () {
      const {
        backgroundColor,
        enableBackgroundImage,
        enableSidebarShadow,
        backgroundImage,
        backgroundImageOpacity
      } = this.props

      return (
        <Fragment>
          <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar}/>
          <ReactCSSTransitionGroup
            component="div"
            className={cx('app-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
            transitionName="SidebarAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}>
            <HeaderLogo/>
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Nav closeMobileSidebar={this.closeMobileSidebar} />
              </div>
            </PerfectScrollbar>
            <div
              className={cx('app-sidebar-bg', backgroundImageOpacity)}
              style={{
                backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
              }}>
            </div>
          </ReactCSSTransitionGroup>
        </Fragment>
      )
    }
}

const mapStateToProps = state => ({
  enableBackgroundImage: state.theme.enableBackgroundImage,
  enableSidebarShadow: state.theme.enableSidebarShadow,
  enableMobileMenu: state.theme.enableMobileMenu,
  backgroundColor: state.theme.backgroundColor,
  backgroundImage: state.theme.backgroundImage,
  backgroundImageOpacity: state.theme.backgroundImageOpacity
})

const mapDispatchToProps = dispatch => ({

  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable))

})

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar)
