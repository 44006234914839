import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import './Groups.sass'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import Loader from 'react-loaders'

import { fetchManageGroups, fetchManageUsers, postManageGroup } from '../../actions'

import {
  Row, Col,
  Card, CardBody
} from 'reactstrap'

import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import ReactTable from 'react-table'

import PageTitle from '../../components/PageTitle/PageTitle'
import GroupModal from '../../components/GroupModal/GroupModal'

class Groups extends PureComponent {
  componentDidMount () {
    this.props.fetchManageGroups()
    this.props.fetchManageUsers()
  }

  _handleRowClick = (rowInfo, column) => {
    if (rowInfo !== undefined && column.Header !== '') {
      return this.props.history.push(`/groups/${rowInfo.original.id}`)
    }
  }

  _btnClicked = (e) => {
    return this.props.history.push(`/groups/add`)
  }

  _renderTable = () => {
    if (this.props.manageGroups.loading) return <div className="table_center"> <Loader type="ball-pulse" /> </div>
    if (!this.props.manageGroups.data) return null
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 110,
        className: 'table_center'
      },
      {
        Header: 'グループ名',
        accessor: 'name',
        className: 'table_center'
      },
      // {
      //   Header: 'リーダー名',
      //   accessor: 'leader_name',
      //   className: 'table_center'
      // },
      {
        Header: '人数',
        accessor: 'member_count',
        Cell: row => (
          <Fragment>
            {(row.value)}人
          </Fragment>
        ),
        className: 'table_center'

      },
      {
        Header: '管理棟数',
        accessor: 'property_count',
        Cell: row => (
          <Fragment>
            {(row.value)}棟
          </Fragment>
        ),
        className: 'table_center'
      }
    ]
    if (this.props.role === 0) {
      columns.push({
        Header: '',
        accessor: 'id',
        Cell: row => (
          <Fragment>
            <GroupModal
              modalTitle = "メンバーを追加する"
              modalContent = 'test'
              data = {this.props.manageUsers.data}
              groupData = {(row)}
              function={this.props.postManageGroup}
              getFunction={this.props.fetchManageGroups}
              getUsersFunction={this.props.fetchManageUsers}
            />
          </Fragment>
        )
      })
    }
    return (
      <ReactTable
        data={this.props.manageGroups.data}
        defaultPageSize={10}
        getTdProps={(state, rowInfo, column) => {
          return {
            onClick: () => this._handleRowClick(rowInfo, column)
          }
        }}
        columns={columns}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  render () {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <PageTitle
              heading="管理グループ一覧"
              icon="pe-7s-key stroke-orange"
              btnSupportedRoles={[0]}
              btnClick = {this._btnClicked}
              btnText = "追加"
              btnColor = "warning"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ manageGroups, manageUsers, auth }) => ({
  manageGroups,
  manageUsers,
  role: auth.user.role
})

const mapDispatchToProps = {
  fetchManageGroups,
  fetchManageUsers,
  postManageGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups)

Groups.propTypes = {
  fetchManageGroups: PropTypes.func,
  manageGroups: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      leader_name: PropTypes.string,
      people_number: PropTypes.number,
      building_number: PropTypes.number
    }))
  }),
  role: PropTypes.number.isRequired
}
Groups.defaultProps = {

}
