import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import './NotFound.sass'
import Loader from 'react-loaders'

class NotFound extends PureComponent {
  render () {
    return (
      <div className="loader-container-wrapper">
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="pacman" />
            </div>
            <h6 className="mt-5">
            お探しのページは見つかりませんでした。
              <small>
                あなたがアクセスしようとしたページは削除されたかURLが変更されているため、<br/>
                見つけることができません。<br/>
                お手数ですが、もう一度お探しください。
              </small>
            </h6>
          </div>
        </div>
      </div>
    )
  }
}
export default NotFound

NotFound.propTypes = {

}
NotFound.defaultProps = {

}
