import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PropTypes from 'prop-types'
import Loader from 'react-loaders'
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { toast } from 'react-toastify'

import { fetchProperty, postProperty, deleteProperty, resetProperty } from '../../actions'

import DropZone from '../../components/DropZone/index'
import PageTitle from '../../components/PageTitle/PageTitle'

import './Property.sass'

class Property extends PureComponent {
  state={
    id: '',
    name: '',
    prefecture: '',
    address1: '',
    address2: '',
    login_id: '',
    password: '',
    tel: '',
    bussiness_hour: '',
    room_count: '',
    image_url: '',
    signage_url: ''
  }

  componentDidMount () {
    if (this.props.match.params.id !== undefined) {
      this.props.fetchProperty(this.props.match.params.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.property.data !== this.props.property.data) {
      this._updateState()
    }
  }

  componentWillUnmount = () => {
    this.props.resetProperty()
  }

  _updateState = () => {
    this.setState({
      id: this.props.property.data.id,
      name: this.props.property.data.name || '',
      prefecture: this.props.property.data.prefecture || '',
      address1: this.props.property.data.address1 || '',
      address2: this.props.property.data.address2 || '',
      login_id: this.props.property.data.login_id || '',
      password: this.props.property.data.password || '',
      tel: this.props.property.data.tel || '',
      bussiness_hour: this.props.property.data.bussiness_hour || '',
      room_count: this.props.property.data.room_count || '',
      image_url: this.props.property.data.image_url || '',
      signage_url: this.props.property.data.signage_url || ''
    })
  }

  _moveInfoPage = () => {
    this.props.history.push(`/properties/${this.state.id}/informations`)
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleCancell = (e) => {
    e.preventDefault()
    return this.props.history.push(`/`)
  }

  _handleImage = (file, image_url) => {
    this.setState({ image: file, image_url })
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    if (this.props.role === 2) return
    const data = {
      name: this.state.name,
      prefecture: this.state.prefecture,
      address1: this.state.address1,
      address2: this.state.address2,
      login_id: this.state.login_id,
      password: this.state.password,
      tel: this.state.tel,
      bussiness_hour: this.state.bussiness_hour,
      room_count: this.state.room_count ? Number(this.state.room_count) : 0, // Convert to number and set to 0 if empty
      // image_url: this.state.image_url, // send base64 image or previous image url
      signage_url: this.state.signage_url
    }
    // Prevent from sending ID when creating new record
    if (this.state.id) {
      data.id = this.state.id
    }
    const image = this.state.image

    const success = () => {
      toast.success(
        '保存が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      this.setState({
        id: '',
        name: '',
        prefecture: '',
        address1: '',
        address2: '',
        login_id: '',
        password: '',
        tel: '',
        bussiness_hour: '',
        room_count: '',
        image_url: '',
        signage_url: ''
      })
      return this.props.history.push(`/`)
    }

    const fail = (message) => {
      toast.warn(
        '保存に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      alert(message)
    }

    this.props.postProperty(data, image, success, fail)
  }

  _handleImageDelete = () => {
    this.setState({
      image_url: '',
      image: ''
    })
  }

  _handleDelete = (e) => {
    e.preventDefault()
    const success = () => {
      toast.success(
        '削除が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '削除に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.log(message)
    }

    // send data to action
    const FinalAnswer = window.confirm(`本当に${this.state.name}を削除しますか`)
    if (FinalAnswer) {
      this.props.deleteProperty(this.props.match.params.id, success, fail)
    }
  }

  _renderTitle = () => {
    const { name } = this.state
    if (this.props.match.params.id !== undefined) {
      return (
        <PageTitle
          heading={name}
          icon="pe-7s-home stroke-orange"
          btnSupportedRoles={[0, 1]}
          btnClick = {this._moveInfoPage}
          btnText = '管理情報'
          btnColor="warning"
        />
      )
    } else {
      return (
        <PageTitle
          heading={name}
          icon="pe-7s-home stroke-orange"
        />
      )
    }
  }

  _renderDeleteBtn = () => {
    if (!this.props.property.deleting) {
      return <Button color="danger" className="mt-2" type="button" onClick={this._handleDelete}>削除</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderBtn = () => {
    if (this.props.role === 2) return null
    if (this.props.match.params.id !== undefined) {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 3 }}>
            <Button outline className=" mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
          <Col md={2}>
            {this._renderDeleteBtn()}
          </Col>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 4 }}>
            <Button outline className="mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
        </Fragment>
      )
    }
  }

  _renderSubmitBtn = () => {
    if (!this.props.property.posting) {
      return <Button color="success" className="mt-2" type="submit">保存</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderForm = () => {
    // console.log(this.props.property.data)
    const {
      name,
      prefecture,
      address1,
      address2,
      login_id,
      password,
      tel,
      bussiness_hour,
      room_count,
      signage_url
    } = this.state
    return (
      <Form onSubmit={this._handleSubmit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="login_id">ログインID<span className="required">*</span></Label>
              <Input name="login_id" id="login_id" value={login_id} onChange={this._handleChange} required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="Password">Password<span className="required">*</span></Label>
              <Input name="password" id="Password" value={password} onChange={this._handleChange}/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="PropertyName">物件名<span className="required">*</span></Label>
          <Input type="text" name="name" id="PropertyName" value={name} onChange={this._handleChange}/>
        </FormGroup>
        <Row form>
          <Col md={2}>
            <FormGroup>
              <Label for="prefecture">都道府県<span className="required">*</span></Label>
              <Input type="text" name="prefecture" id="prefecture" value={prefecture} onChange={this._handleChange} required/>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="address1">市区町村<span className="required">*</span></Label>
              <Input type="text" name="address1" id="address1" value={address1} onChange={this._handleChange} required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="address2">番地</Label>
              <Input type="text" name="address2" id="address2" value={address2} onChange={this._handleChange}/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="signage_url">デジタルサイネージURL</Label>
          <Input type="text" name="signage_url" id="signage_url" value={signage_url} onChange={this._handleChange} />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="tel">電話番号</Label>
              <Input type="text" name="tel" id="tel" value={tel} onChange={this._handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="room_count">戸数</Label>
              <Input type="number" name="room_count" id="room_count" value={room_count} onChange={this._handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="bussiness_hour">問い合わせ可能時間</Label>
              <Input type="text" name="bussiness_hour" id="bussiness_hour" value={bussiness_hour} onChange={this._handleChange} />
            </FormGroup>
          </Col>
          <Col>
            <Label for="image_url">画像</Label>
            <DropZone
              files={this.state.image}
              imageUrl={this.state.image_url} // pass image URL or base64 for preview
              handleChange={this._handleImage}
              imagePropsUrl={this.props.property.data ? this.props.property.data.image_url : ''}
              reset={this._handleImageDelete}
            />
          </Col>
        </Row>
        <Row>
          {this._renderBtn()}
        </Row>
      </Form>
    )
  }

  render () {
    const { data, loading } = this.props.property
    if (this.props.match.params.id !== undefined) {
      if (loading) return <div className="table_center maxHeight"><Loader type="ball-pulse" /></div>
      if (!data) return null
    }
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            {this._renderTitle()}
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <p><span className="required">*</span>は必須項目です。</p>
                  {this._renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
const mapStateToProps = ({ property, auth }) => ({
  property,
  role: auth.user.role
})

const mapDispatchToProps = {
  fetchProperty, postProperty, deleteProperty, resetProperty
}

export default connect(mapStateToProps, mapDispatchToProps)(Property)

Property.propTypes = {
  fetchProperty: PropTypes.func
}
Property.defaultProps = {

}
