import { FETCH_MANAGE_USERS, FETCH_MANAGE_USERS_SUCCESS, FETCH_MANAGE_USERS_FAIL } from './types'

export const fetchManageUsers = () => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_MANAGE_USERS })
  try {
    const res = await api.get(`members`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_MANAGE_USERS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_MANAGE_USERS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_MANAGE_USERS_SUCCESS })
  }
}
