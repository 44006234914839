import { combineReducers } from 'redux'
import auth from './authReducer'
import theme from './themeReducer'
import properties from './propertiesReducer'
import property from './propertyReducer'
import residents from './residentReducer'
import notices from './noticesReducer'
import notice from './noticeReducer'
import noticeStatus from './noticeStatusReducer'
import informations from './informationsReducer'
import information from './informationReducer'
import informationStatus from './informationStatusReducer'
import manageUsers from './manageUsersReducer'
import manageUser from './manageUserReducer'
import manageGroups from './manageGroupsReducer'
import manageGroup from './manageGroupReducer'

const rootReducer = combineReducers({
  auth,
  theme,
  properties,
  property,
  residents,
  notices,
  notice,
  noticeStatus,
  informations,
  information,
  informationStatus,
  manageUsers,
  manageUser,
  manageGroups,
  manageGroup
})

export default rootReducer
