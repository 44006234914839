import { FETCH_NOTICES, FETCH_NOTICES_SUCCESS, FETCH_NOTICES_FAIL } from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTICES:
      return { ...state, loading: true }
    case FETCH_NOTICES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_NOTICES_FAIL:
      return { ...state, loading: false }
    default:
      return (state)
  }
}
