/* eslint-disable */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import Hamburger from 'react-hamburgers'

import AppMobileMenu from '../AppMobileMenu/AppMobileMenu'

import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall
} from '../../actions'

class HeaderLogo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        }
    }

    toggleEnableClosedSidebar = () => {
        const { enableClosedSidebar, setEnableClosedSidebar } = this.props
        setEnableClosedSidebar(!enableClosedSidebar)
        this.setState({ active: !this.state.active })
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false
    };

    render() {
        const {
            enableClosedSidebar
        } = this.props

        const {
        } = this.state

        return (
            <Fragment>
                <div className="app-header__logo">
                    <div className="logo-src" />
                    <div className="header__pane ml-auto">
                        <div>
                            <Hamburger
                                active={enableClosedSidebar}
                                type="elastic"
                                onClick={this.toggleEnableClosedSidebar}
                            />
                        </div>
                    </div>
                </div>
                <AppMobileMenu />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableClosedSidebar: state.theme.enableClosedSidebar,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableMobileMenuSmall: state.theme.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({

    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable))

})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo)
