import {
  FETCH_NOTICE,
  FETCH_NOTICE_SUCCESS,
  FETCH_NOTICE_FAIL,
  POST_NOTICE,
  POST_NOTICE_FAIL,
  POST_NOTICE_SUCCESS,
  DELETE_NOTICE,
  DELETE_NOTICE_SUCCESS,
  DELETE_NOTICE_FAIL,
  RESET_NOTICE
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  deleting: false,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTICE:
      return { ...state, loading: true }
    case FETCH_NOTICE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_NOTICE_FAIL:
      return { ...state, loading: false }
    case POST_NOTICE:
      return { ...state, posting: true, deleting: true }
    case POST_NOTICE_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case POST_NOTICE_FAIL:
      return { ...state, posting: false, deleting: false }
    case DELETE_NOTICE:
      return { ...state, posting: true, deleting: true }
    case DELETE_NOTICE_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case DELETE_NOTICE_FAIL:
      return { ...state, posting: false, deleting: false }
    case RESET_NOTICE:
      return INITIAL_STATE
    default:
      return (state)
  }
}
