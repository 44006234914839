import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import './Residents.sass'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Loader from 'react-loaders'
import { toast } from 'react-toastify'
import moment from 'moment'

import { fetchResidents, banResident } from '../../actions'

import {
  Row, Col, Button,
  Card, CardBody,
  CustomInput
} from 'reactstrap'

import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import ReactTable from 'react-table'

import PageTitle from '../../components/PageTitle/PageTitle'

class Residents extends PureComponent {
  state = {
    prefecture: '',
    address: '',
    property: ''
  }

  componentDidMount = () => {
    this.props.fetchResidents()
  }

  _handleBan = (e, data) => {
    e.preventDefault()
    // console.log(data)
    const success = () => {
      toast.success(
        'アカウントを停止しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        'アカウントの停止に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.log(message)
    }

    // send data to action
    const FinalAnswer = window.confirm('本当に' + data.row.property_name + 'の' + data.row.room_number + '号室のアカウントを停止しますか。')
    if (FinalAnswer) {
      this.props.banResident(data.row._original.id, success, fail)
    }
  }

  _handlePrefectureChange = (e) => {
    this.setState({
      prefecture: e.target.value,
      address: '',
      property: ''
    })
  }

  _handleAddressChange = (e) => {
    this.setState({
      address: e.target.value,
      property: ''
    })
  }

  _handlePropertyChange = (e) => {
    this.setState({ property: e.target.value })
  }

  _renderStatusbtn = (status) => {
    if (this.props.role === 2) {
      if (status.value === 0) return <Button color="danger" type="button" disabled>利用停止中</Button>
      return null
    }
    switch (status.value) {
      case 0:
        return <Button color="danger" type="button" disabled>利用停止中</Button>
      default:
        return <Button outline color="danger" type="button" onClick={(e) => this._handleBan(e, status)}>利用停止にする</Button>
    }
  }

  _renderGender = (gender) => {
    switch (gender) {
      case 0:
        return '男'
      case 1:
        return '女'
      default:
        return null
    }
  }

  _renderType = (type) => {
    switch (type) {
      case 0:
        return '所有者'
      case 1:
        return '入居者'
      case 2:
        return '入居者の家族'
      default:
        return null
    }
  }

  _renderTable=() => {
    if (this.props.residents.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    if (!this.props.residents.data) return null

    const data = this.props.residents.data
      .filter(resident => {
        const { prefecture, address, property } = this.state
        // No prefecture selected, return all residents
        if (!prefecture) {
          return true
        } else {
          if (prefecture === resident.prefecture) {
            // No address selected, return all residents in the selected prefecture
            if (!address) {
              return true
            } else if (address === resident.address1) {
              // No property selected or one selected, return corresponding residents
              if (!property || property === resident.property_name) {
                return true
              }
            }
          }
        }
        return false
      })

    return (
      <ReactTable
        data={data}
        defaultPageSize={10}
        columns={[
          {
            Header: '都道府県',
            accessor: 'prefecture',
            className: 'table_center'
          },
          {
            Header: '物件名',
            accessor: 'property_name',
            className: 'table_center'
          },
          {
            Header: '入居者名',
            accessor: 'name',
            className: 'table_center'
          },
          {
            Header: '電話番号',
            accessor: 'phone',
            className: 'table_center'
          },
          {
            Header: '部屋番号',
            accessor: 'room_number',
            maxWidth: 110,
            className: 'table_center'
          },
          {
            Header: '属性',
            accessor: 'attribute',
            Cell: row => (
              <Fragment>
                {this._renderType(row.value)}
              </Fragment>
            ),
            className: 'table_center'
          },
          {
            Header: '性別',
            accessor: 'gender',
            maxWidth: 80,
            Cell: row => (
              <Fragment>
                {this._renderGender(row.value)}
              </Fragment>
            ),
            className: 'table_center'
          },
          {
            Header: '年齢',
            accessor: 'age',
            className: 'table_center',
            maxWidth: 120
          },
          {
            Header: '最終ログイン日',
            accessor: 'last_login_date',
            className: 'table_center',
            Cell: row => (
              <Fragment>
                {row.value ? moment(row.value).format('YYYY/MM/DD') : 'no login'}
              </Fragment>
            )
          },
          {
            Header: '利用停止',
            accessor: 'status',
            maxWidth: 175,
            minWidth: 130,
            Cell: row => (
              <Fragment>
                {this._renderStatusbtn(row)}
              </Fragment>
            )
          }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  _renderPrefOptions = () => {
    const { data } = this.props.residents
    if (!data || data.length === 0) return null

    const prefectures = []
    data.forEach(property => {
      if (!prefectures.includes(property.prefecture)) prefectures.push(property.prefecture)
    })

    return prefectures.map((prefecture, i) => {
      return <option key={i} value={prefecture}>{prefecture}</option>
    })
  }

  _renderCityOptions = () => {
    const { data } = this.props.residents
    if (!data || data.length === 0) return null

    const addresses = []
    data.forEach(property => {
      if (!addresses.includes(property.address1) && (!this.state.prefecture || property.prefecture === this.state.prefecture)) addresses.push(property.address1)
    })

    return addresses.map((address, i) => {
      return <option key={i} value={address}>{address}</option>
    })
  }

  _renderPropertyOptions = () => {
    const { data } = this.props.residents
    if (!data || data.length === 0) return null

    const properties = []
    data.forEach(property => {
      if (!properties.includes(property.property_name) && (!this.state.address || property.address1 === this.state.address)) properties.push(property.property_name)
    })

    return properties.map((property, i) => {
      return <option key={i} value={property}>{property}</option>
    })
  }

  render () {
    return (
      <Fragment >
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <PageTitle
              heading="入居者一覧"
              icon="pe-7s-user stroke-orange"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col className="resident_select">
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        name="customSelect"
                        defaultValue={this.state.prefecture}
                        onChange={this._handlePrefectureChange}
                      >
                        <option value="">全ての都道府県</option>
                        { this._renderPrefOptions() }
                      </CustomInput>
                    </Col>
                    {
                      this.state.prefecture
                        ? (
                          <Col className="resident_select">
                            <CustomInput
                              type="select"
                              id="exampleCustomSelect"
                              name="customSelect"
                              defaultValue={this.state.address}
                              onChange={this._handleAddressChange}
                            >
                              <option value="">全ての市区町村</option>
                              { this._renderCityOptions() }
                            </CustomInput>
                          </Col>
                        )
                        : null
                    }
                    {
                      this.state.address
                        ? (
                          <Col className="resident_select">
                            <CustomInput
                              type="select"
                              id="exampleCustomSelect"
                              name="customSelect"
                              defaultValue={this.state.property}
                              onChange={this._handlePropertyChange}
                            >
                              <option value="">全ての物件</option>
                              { this._renderPropertyOptions() }
                            </CustomInput>
                          </Col>
                        )
                        : null
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment >
    )
  }
}
const mapStateToProps = ({ residents, auth }) => ({
  residents, role: auth.user.role
})

const mapDispatchToProps = {
  fetchResidents, banResident
}

export default connect(mapStateToProps, mapDispatchToProps)(Residents)

Residents.propTypes = {
  fetchResidents: PropTypes.func,
  residents: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      room_number_date: PropTypes.number,
      type: PropTypes.oneOf([0, 1, 2]),
      gender: PropTypes.oneOf([0, 1]),
      age: PropTypes.number,
      last_login: PropTypes.string,
      status: PropTypes.number
    }))
  })
}
Residents.defaultProps = {

}
