import {
  FETCH_MANAGE_GROUP,
  FETCH_MANAGE_GROUP_SUCCESS,
  FETCH_MANAGE_GROUP_FAIL,
  POST_MANAGE_GROUP,
  POST_MANAGE_GROUP_SUCCESS,
  POST_MANAGE_GROUP_FAIL,
  DELETE_MANAGE_GROUP,
  DELETE_MANAGE_GROUP_SUCCESS,
  DELETE_MANAGE_GROUP_FAIL,
  RESET_MANAGE_GROUP
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  deleting: false,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MANAGE_GROUP:
      return {
        ...state, loading: true
      }
    case FETCH_MANAGE_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_MANAGE_GROUP_FAIL:
      return {
        ...state, loading: false
      }
    case POST_MANAGE_GROUP:
      return { ...state, posting: true, deleting: true }
    case POST_MANAGE_GROUP_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case POST_MANAGE_GROUP_FAIL:
      return { ...state, posting: false, deleting: false }
    case DELETE_MANAGE_GROUP:
      return { ...state, posting: true, deleting: true }
    case DELETE_MANAGE_GROUP_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case DELETE_MANAGE_GROUP_FAIL:
      return { ...state, posting: false, deleting: false }
    case RESET_MANAGE_GROUP:
      return INITIAL_STATE
    default:
      return (state)
  }
}
