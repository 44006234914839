/* eslint-disable */
import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, CustomInput, Form } from 'reactstrap'
import _ from 'lodash'
import { fetchResidents } from './../../actions'
import { connect } from 'react-redux'
import ModalItem from '../ModalItem/ModalItem'
import Loader from 'react-loaders'

class MainModal extends PureComponent {
  componentDidMount = () => {
    this.props.fetchResidents()
  }

  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  _createData = (data,thisId) => {
    const sorted = {}
    data.filter(item => item.property_id === Number(thisId) && Number(item.status) === 1).forEach(item => {
      const {
        id,
        room_number,
        property_id,
        property_name,
        prefecture
      } = item

      if (sorted[property_id]) {
        sorted[property_id] = {
          ...sorted[property_id],
          residents: [...sorted[property_id].residents, {
            id,
            room_number
          }]
        }
      } else {
        sorted[property_id] = {
          property_name,
          prefecture,
          residents: [{
            id,
            room_number
          }]
        }
      }
    })
    return sorted
  }

  _renderItems = () => {
    const { residents, handlePropertyCheck, notification_users, propertyId } = this.props
    if (residents.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    if (!residents.data) return null
    // this._createData(residents.data, propertyId)
    return (
      _.map(this._createData(residents.data, propertyId), (value, name) => {
        const isChecked = () => {
          const ids = []
          value.residents.forEach(resident => {
            ids.push(resident.id)
          })

          return ids.every(id => notification_users.includes(id))
        }
        return (
          <Fragment key={name}>
            {/* {console.log('props:',residents.data,'name:', name)} */}
            <CustomInput type="checkbox" id={name} label={value.property_name} onChange={(e) => handlePropertyCheck(name, e, value.residents)} checked={isChecked()} />
            {this._renderItem(value.residents)}
          </Fragment>
        )
      })
    )
  }

  _renderItem = (residents) => {
    return (
      _.map(residents, (value, name) => {
        // console.log('name2:',name,'value2:',value)
        return (
          <ModalItem key={value.id} item={value} handleCheck={this.props.handleCheck} notification_users={this.props.notification_users} />
        )
      })
    )
  }

  render() {
    return (
      <span className="d-block mb-2">
        <Button color="primary" onClick={this.toggle}>{this.props.modalTitle}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                {this._renderItems()}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>閉じる</Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = ({ residents }) => ({
  residents
})

const mapDispatchToProps = {
  fetchResidents
}

export default connect(mapStateToProps, mapDispatchToProps)(MainModal)
