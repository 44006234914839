import { LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL, LOGOUT_USER } from './types'
import { encrypt, decrypt, TOKEN_NAME, IDENTITY_NAME } from '../constants'

export const loginUser = (login_id, password) => async (dispatch, getState, api) => {
  dispatch({ type: LOGIN_USER })
  try {
    const res = await api.post(`login`, {
      login_id,
      password
    })

    const { token, user } = res.data.data
    localStorage.setItem(IDENTITY_NAME, encrypt(JSON.stringify(user)))
    localStorage.setItem(TOKEN_NAME, token)
    // localStorage.setItem(IDENTITY_NAME, encrypt(JSON.stringify(user)))

    dispatch({ type: LOGIN_USER_SUCCESS, payload: { token, user } })
  } catch (error) {
    const err = (error.response && error.response.data) ? error.response.data : 'Unknown error'
    dispatch({ type: LOGIN_USER_FAIL, payload: err })
  }
}

export const logoutUser = () => async dispatch => {
  localStorage.removeItem(IDENTITY_NAME)
  localStorage.removeItem(TOKEN_NAME)
  dispatch({ type: LOGOUT_USER })
}

export const checkAuth = () => dispatch => {
  const token = localStorage.getItem(TOKEN_NAME)
  const identity = localStorage.getItem(IDENTITY_NAME)
  const user = identity ? JSON.parse(decrypt(identity)) : null

  if (token && user && user.id) {
    dispatch({ type: LOGIN_USER_SUCCESS, payload: { token, user } })
  } else {
    localStorage.removeItem(IDENTITY_NAME)
    localStorage.removeItem(TOKEN_NAME)
    dispatch({
      type: LOGIN_USER_FAIL,
      payload: null
    })
  }
}
