import { FETCH_MANAGE_GROUPS, FETCH_MANAGE_GROUPS_SUCCESS } from './types'

export const fetchManageGroups = () => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_MANAGE_GROUPS })
  try {
    const res = await api.get(`groups`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_MANAGE_GROUPS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_MANAGE_GROUPS_SUCCESS })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_MANAGE_GROUPS_SUCCESS })
  }
}
