import {
  FETCH_INFORMATION,
  FETCH_INFORMATION_SUCCESS,
  FETCH_INFORMATION_FAIL,
  POST_INFORMATION,
  POST_INFORMATION_SUCCESS,
  POST_INFORMATION_FAIL,
  DELETE_INFORMATION,
  DELETE_INFORMATION_SUCCESS,
  DELETE_INFORMATION_FAIL,
  RESET_INFORMATION,
  FETCH_INFORMATION_TAGS,
  FETCH_INFORMATION_TAGS_SUCCESS,
  FETCH_INFORMATION_TAGS_FAIL
} from './types'

export const fetchInformation = (id) => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_INFORMATION })
  try {
    const res = await api.get(`informations/detail/${id}`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_INFORMATION_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_INFORMATION_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_INFORMATION_FAIL })
  }
}
export const fetchInformationTags = () => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_INFORMATION_TAGS })
  try {
    const res = await api.get(`notification_tags`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_INFORMATION_TAGS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_INFORMATION_TAGS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_INFORMATION_TAGS_FAIL })
  }
}

export const postInformation = (propertyId, data, image, success, fail) => async (dispatch, getState, api) => {
  dispatch({
    type: POST_INFORMATION
  })
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      // console.log(key, typeof data[key])
      if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
      // formData.append(key, JSON.stringify(data[key]))
    })
    // formData.append('data', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }
    const res = await api.post(`informations/${propertyId}`, formData, config)
    if (res.data.success) {
      dispatch({
        type: POST_INFORMATION_SUCCESS
      })
      if (success) success()
    } else {
      dispatch({
        type: POST_INFORMATION_FAIL
      })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      dispatch({
        type: POST_INFORMATION_SUCCESS
      })
      if (success) success()
      return
    }
    dispatch({
      type: POST_INFORMATION_FAIL
    })
    fail('エラー')
  }
}

export const deleteInformation = (propertyId, id, success, fail) => async (dispatch, getState, api) => {
  dispatch({
    type: DELETE_INFORMATION
  })
  try {
    const res = await api.delete(`informations/${propertyId}/${id}`)
    if (res.data.success) {
      dispatch({
        type: DELETE_INFORMATION_SUCCESS
      })
      if (success) success()
    } else {
      dispatch({
        type: DELETE_INFORMATION_FAIL
      })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: DELETE_INFORMATION_FAIL
    })
  }
}

export const resetInformation = () => {
  return { type: RESET_INFORMATION }
}
