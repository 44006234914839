import {
  FETCH_INFORMATIONS_SUCCESS,
  FETCH_INFORMATIONS,
  FETCH_INFORMATIONS_FAIL,
  FETCH_INFORMATION_STATUS,
  FETCH_INFORMATION_STATUS_SUCCESS,
  FETCH_INFORMATION_STATUS_FAIL,
  RESET_INFORMATION_STATUS
} from './types'

export const fetchInformations = (id) => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_INFORMATIONS })
  try {
    const res = await api.get(`informations/${id}`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_INFORMATIONS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_INFORMATIONS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_INFORMATIONS_FAIL })
  }
}

export const fetchInformationStatus = (id) => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_INFORMATION_STATUS })
  try {
    const res = await api.get(`notices/${id}/status`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_INFORMATION_STATUS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_INFORMATION_STATUS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_INFORMATION_STATUS_FAIL })
  }
}

export const resetInformationStatus = () => {
  return { type: RESET_INFORMATION_STATUS }
}
