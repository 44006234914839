import {
  FETCH_INFORMATION_STATUS,
  FETCH_INFORMATION_STATUS_SUCCESS,
  FETCH_INFORMATION_STATUS_FAIL,
  RESET_INFORMATION_STATUS
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INFORMATION_STATUS:
      return { ...state, loading: true }
    case FETCH_INFORMATION_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_INFORMATION_STATUS_FAIL:
      return { ...state, loading: false }
    case RESET_INFORMATION_STATUS:
      return INITIAL_STATE
    default:
      return (state)
  }
}
