import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// TODO:
// use render function to render children instead of children props
// this should prevent useless rerenders

class RoleControlled extends PureComponent {
  // shouldComponentUpdate = (nextprops, nextstate) => {
  //   // console.log('log equality: ', this.props.supportedRoles === nextprops.supportedRoles)
  //   if (this.props.supportedRoles !== nextprops.supportedRoles) {
  //     console.log('props', this.props)
  //     console.log('next props', nextprops)
  //     return false
  //   } else {
  //     return true
  //   }
  // }
  render () {
    const { userRole, supportedRoles, exceptedRoles, children } = this.props
    if (supportedRoles) {
      if (!supportedRoles.includes(userRole)) return null
      return children
    }

    if (exceptedRoles) {
      if (exceptedRoles.includes(userRole)) return null
      return children
    }
    return null
  }
}

function mapStateToProps ({ auth }) {
  return { userRole: auth.user.role }
}

export default connect(mapStateToProps, null)(RoleControlled)

RoleControlled.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  userRole: PropTypes.number.isRequired,
  supportedRoles: (props, propName, componentName) => {
    if (props.supportedRoles) {
      if (Array.isArray(props.supportedRoles)) {
        if (!props.supportedRoles.every((val) => Number.isInteger(val))) {
          return new Error(`The props '${propName}' must be an array of Numbers in '${componentName}'.`)
        }
      } else {
        return new Error(`The props '${propName}' must be an array in '${componentName}'.`)
      }
    }
    if (!props.supportedRoles && !props.exceptedRoles) {
      return new Error(`One of props 'supportedRoles' or 'exceptedRoles' was not specified in '${componentName}'.`)
    }
  },
  exceptedRoles: (props, propName, componentName) => {
    if (props.exceptedRoles) {
      if (Array.isArray(props.exceptedRoles)) {
        if (!props.exceptedRoles.every((val) => Number.isInteger(val))) {
          return new Error(`The props '${propName}' must be an array of Numbers in '${componentName}'.`)
        }
      } else {
        return new Error(`The props '${propName}' must be an array in '${componentName}'.`)
      }
    }
    if (!props.supportedRoles && !props.exceptedRoles) {
      return new Error(`One of props 'supportedRoles' or 'exceptedRoles' was not specified in '${componentName}'.`)
    }
  }
}
