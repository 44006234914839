import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import Loader from 'react-loaders'
import ReactTable from 'react-table'

import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import { fetchNoticeStatus, resetNoticeStatus } from '../../actions'

class StatusModal extends PureComponent {
  state = {
    showModal: true
  }

  componentDidMount = () => {
    this.props.fetchNoticeStatus(this.props.id)
  }

  componentWillUnmount = () => {
    this.props.resetNoticeStatus()
  }

  _renderModalBody = () => {
    if (this.props.noticeStatus.loading) {
      return (
        <div className="table_center">
          <Loader type="ball-pulse" />
        </div>
      )
    }
    if (!this.props.noticeStatus.data) return null
    return (
      <ReactTable
        data={this.props.noticeStatus.data}
        defaultPageSize={10}
        columns={[
          {
            Header: '部屋番号',
            accessor: 'room_number'
          },
          {
            Header: '物件名',
            accessor: 'property_name'
          },
          {
            Header: '開封状況',
            accessor: 'read_flag',
            Cell: row => row.value === 0 ? '未開封' : '開封済'
          },
          {
            Header: '開封日時',
            accessor: 'read_at',
            Cell: row => row.value ? moment(row.value).format('YYYY/MM/DD HH:mm') : ''
          }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  render () {
    const { closeModal } = this.props
    const { showModal } = this.state
    return (
      <Modal isOpen={showModal} toggle={closeModal} size="lg" className="notice_status_modal">
        <ModalHeader toggle={closeModal}>管理情報 | 開封状況</ModalHeader>
        <ModalBody>
          { this._renderModalBody() }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>閉じる</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = ({ noticeStatus }) => ({
  noticeStatus
})

const mapDispatchToProps = {
  fetchNoticeStatus,
  resetNoticeStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusModal)

StatusModal.propTypes = {
  id: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchNoticeStatus: PropTypes.func.isRequired,
  resetNoticeStatus: PropTypes.func.isRequired
}
