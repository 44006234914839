/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import cx from 'classnames';
import './PageTitle.sass'

import {Button} from 'reactstrap'
import RoleControlled from '../RoleControlled/RoleControlled';

class PageTitle extends Component {
    _renderBtn = () => {
      const {
        btnSupportedRoles,
        btnClick,
        btnColor,
        btnText
       } = this.props
      if (btnText) {
        return (
          <RoleControlled supportedRoles={btnSupportedRoles}>
            <Button color={btnColor} onClick={btnClick}> {btnText} </Button>
          </RoleControlled>
        )
      }
    }

    _rednerIcon = () => {
      const {
        enablePageTitleIcon,
        icon,
        image
      } = this.props
      if(image){
        return (
          <div
            className={cx("page-title-icon","page-title-image", { 'd-none': !enablePageTitleIcon })} style={{ backgroundImage: `url(${image})`}}>
          </div>

        )
      }else{
        return (
          <div
            className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
            <i className={icon} />
          </div>
        )
      }
    }

    render() {
      let {
        enablePageTitleSubheading,
        heading,
        subheading,
      } = this.props

        return (
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                {this._rednerIcon()}
                <div>
                  {heading}
                  <div
                    className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                    {subheading}
                  </div>
                </div>
              </div>

              <div className="page-title-actions">
                {this._renderBtn()}
              </div>
            </div>
          </div>
        )
    }
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.theme.enablePageTitleIcon,
  enablePageTitleSubheading: state.theme.enablePageTitleSubheading,
  role: state.auth.user.role
})

export default connect(mapStateToProps)(PageTitle);

PageTitle.propTypes = {
  enablePageTitleIcon: PropTypes.bool.isRequired,
  enablePageTitleSubheading: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  role: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  image: PropTypes.string,
  btnColor: PropTypes.string,
  btnText: PropTypes.string,
  btnSupportedRoles: PropTypes.array,
}
