import axios from 'axios'
import { API_URL } from '../constants'

const configureAxios = () => {
  const getHeaders = () => {
    const token = localStorage.getItem('sumitomo_token')
    return token
      ? { Authorization: `Bearer ${token}` }
      : null
  }
  const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 30 * 1000 // API request timeout set to 30s
  })

  // abort duplicate request
  const pending = {}
  const handleCancel = (config, cancel) => {
    const params = config.params ? `_${JSON.stringify(config.params)}` : ''
    const url = config.url.indexOf(config.baseURL) !== -1 ? config.url.replace(config.baseURL, '') : config.url
    const flagUrl = `${config.method.toUpperCase()}_${url}${params}`
    // console.log('flagURL', flagUrl)
    if (flagUrl in pending) {
      // console.log('flag in pending', flagUrl, pending)
      if (cancel) {
        // console.log('abort request')
        cancel('Operation canceled to prevent duplicate request') // abort the request
      } else {
        // console.log('remove from pending')
        delete pending[flagUrl] // remove from pending
      }
    } else {
      if (cancel) {
        // console.log('set to pending', flagUrl, pending)
        pending[flagUrl] = cancel // store the cancel function
      }
    }
    // console.log('end pending', flagUrl, pending)
  }

  // Set header in interceptor instead of instance definition
  // Prevent duplicate request
  axiosInstance.interceptors.request.use(async config => {
    const CancelToken = axios.CancelToken
    config.cancelToken = new CancelToken(function executor (c) {
      handleCancel(config, c)
    })
    config.headers = await getHeaders()
    return config
  }, error => {
    Promise.reject(error)
  })

  axiosInstance.interceptors.response.use(response => {
    handleCancel(response.config)
    return response
  }, error => {
    console.log('error', error)
    handleCancel(error.config)
    if (!axios.isCancel(error)) {
      return Promise.reject(error)
    } else {
      // return empty object for aborted request
      return Promise.resolve({})
    }
  })

  return axiosInstance
}

export default configureAxios
