import React, { PureComponent, Fragment } from 'react'
import { ToastContainer } from 'react-toastify'
import { Switch, Route } from 'react-router-dom'
// import PropTypes from 'prop-types'

import ReactResizeDetector from 'react-resize-detector'
import cx from 'classnames'

import { connect } from 'react-redux'

import RoleRoute from './RoleRoute'

import Sidebar from '../components/SideBar/SideBar'
import Header from '../components/Header/Header'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'

// import Main from '../Layout/AppMain'
import Properties from '../pages/Properties'
import Property from '../pages/Property'
import Notices from '../pages/Notices'
import Notice from '../pages/Notice'
import Informations from '../pages/Informations'
import Information from '../pages/Information'
import Residents from '../pages/Residents'
// import Resident from '../pages/Resident'
import ManageUsers from '../pages/ManageUsers'
import ManageUser from '../pages/ManageUser'
import Groups from '../pages/Groups'
import Group from '../pages/Group'

// import Members from '../pages/Members'
import NotFound from '../pages/NotFound'

import 'react-toastify/dist/ReactToastify.min.css'

class App extends PureComponent {
  state = {
    closedSmallerSidebar: false,
    appClasses: ''
  }

  componentDidUpdate (prevProps, prevState) {
    this._updateClasses()
  }

  _updateClasses = (width) => {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt
    } = this.props
    const appClasses = cx(
      'app-container app-theme-' + colorScheme,
      { 'fixed-header': enableFixedHeader },
      { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
      { 'fixed-footer': enableFixedFooter },
      { 'closed-sidebar': enableClosedSidebar || width < 1250 },
      { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
      { 'sidebar-mobile-open': enableMobileMenu },
      { 'body-tabs-shadow-btn': enablePageTabsAlt }
    )
    this.setState({ appClasses })
  }

  render () {
    return (
      <Fragment>
        <ReactResizeDetector handleWidth handleHeight onResize={this._updateClasses} />
        <div className={`app ${this.state.appClasses}`}>
          <main>
            <ToastContainer
              hideProgressBar={true}
              // closeButton={<SnackBarCloseBtn />}
              className="toast_container"
            />
            <Header />
            <div className="app-main">
              <Sidebar />
              <div className="app-main__outer">
                <div className="app-main__inner">
                  <Breadcrumbs/>
                  <Switch>
                    <Route path="/" exact component={Properties} />
                    <Route path="/properties/add" exact component={Property} />
                    <Route path="/properties/:id" exact component={Property} />
                    <Route path="/notices" exact component={Notices} />
                    <Route path="/notices/add" exact component={Notice} />
                    <Route path="/notices/:id" exact component={Notice} />
                    <Route path="/properties/:id/informations" exact component={Informations} />
                    <Route path="/properties/:propertyId/informations/add" exact component={Information} />
                    <Route path="/properties/:propertyId/informations/:id" exact component={Information} />
                    <Route path="/residents" exact component={Residents} />
                    {/* <Route path="/resident" exact component={Resident} /> */}
                    <RoleRoute exceptedRoles={[2]} path="/manage_users" exact component={ManageUsers} />
                    <RoleRoute exceptedRoles={[2]} path="/manage_users/add" exact component={ManageUser} />
                    <RoleRoute exceptedRoles={[2]} path="/manage_users/:id" exact component={ManageUser} />
                    <RoleRoute exceptedRoles={[2]} path="/groups" exact component={Groups} />
                    <RoleRoute exceptedRoles={[2]} path="/groups/add" exact component={Group} />
                    <RoleRoute exceptedRoles={[2]} path="/groups/:id" exact component={Group} />
                    {/* <Route path="/members" exact component={Members} /> */}
                    {/* <Route path="/template" component={Main} /> */}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </div>
          </main>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProp = state => ({
  colorScheme: state.theme.colorScheme,
  enableFixedHeader: state.theme.enableFixedHeader,
  enableMobileMenu: state.theme.enableMobileMenu,
  enableFixedFooter: state.theme.enableFixedFooter,
  enableFixedSidebar: state.theme.enableFixedSidebar,
  enableClosedSidebar: state.theme.enableClosedSidebar,
  enablePageTabsAlt: state.theme.enablePageTabsAlt

})

export default connect(mapStateToProp)(App)
