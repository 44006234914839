import {
  FETCH_MANAGE_USER,
  FETCH_MANAGE_USER_SUCCESS,
  FETCH_MANAGE_USER_FAIL,
  POST_MANAGE_USER,
  POST_MANAGE_USER_SUCCESS,
  POST_MANAGE_USER_FAIL,
  DELETE_MANAGE_USER,
  DELETE_MANAGE_USER_SUCCESS,
  DELETE_MANAGE_USER_FAIL,
  RESET_MANAGE_USER
} from '../actions/types'

const INITIAL_STATE = { loading: true, posting: false, deleting: false, data: null }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MANAGE_USER:
      return { ...state, loading: true }
    case FETCH_MANAGE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_MANAGE_USER_FAIL:
      return { ...state, loading: false }
    case POST_MANAGE_USER:
      return { ...state, posting: true, deleting: true }
    case POST_MANAGE_USER_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case POST_MANAGE_USER_FAIL:
      return { ...state, posting: false, deleting: false }
    case DELETE_MANAGE_USER:
      return { ...state, posting: true, deleting: true }
    case DELETE_MANAGE_USER_SUCCESS:
      return {
        ...state,
        posting: false,
        deleting: false
      }
    case DELETE_MANAGE_USER_FAIL:
      return { ...state, posting: false, deleting: false }
    case RESET_MANAGE_USER:
      return INITIAL_STATE
    default:
      return (state)
  }
}
