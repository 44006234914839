import {
  FETCH_RESIDENTS,
  FETCH_RESIDENTS_SUCCESS,
  FETCH_RESIDENTS_FAIL,
  BAN_RESIDENTS,
  BAN_RESIDENTS_SUCCESS,
  BAN_RESIDENTS_FAIL
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  banning: false,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RESIDENTS:
      return { ...state, loading: true }
    case FETCH_RESIDENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_RESIDENTS_FAIL:
      return { ...state, loading: false }
    case BAN_RESIDENTS:
      return { ...state, banning: true }
    case BAN_RESIDENTS_SUCCESS:
      return { ...state, banning: false }
    case BAN_RESIDENTS_FAIL:
      return { ...state, banning: false }
    default:
      return (state)
  }
}
