import { FETCH_RESIDENTS, FETCH_RESIDENTS_SUCCESS, FETCH_RESIDENTS_FAIL, BAN_RESIDENTS, BAN_RESIDENTS_SUCCESS, BAN_RESIDENTS_FAIL } from './types'

export const fetchResidents = (options) => async (dispatch, getState, api) => {
  if (!options || !options.silentUpdate) {
    dispatch({ type: FETCH_RESIDENTS })
  }
  try {
    const res = await api.get(`residents`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_RESIDENTS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_RESIDENTS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_RESIDENTS_FAIL })
  }
}

export const banResident = (id, success, fail) => async (dispatch, getState, api) => {
  dispatch({ type: BAN_RESIDENTS })
  try {
    const res = await api.put(`property/detail/${id}/ban`)
    if (res.data.success) {
      dispatch({ type: BAN_RESIDENTS_SUCCESS })
      dispatch(fetchResidents({ silentUpdate: true }))
      if (success) success()
    } else {
      dispatch({ type: BAN_RESIDENTS_FAIL })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: BAN_RESIDENTS_FAIL
    })
  }
}
