import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import './Informations.sass'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import moment from 'moment'
import Loader from 'react-loaders'

import { fetchProperty, fetchInformations } from '../../actions'

import {
  Row, Col,
  Card, CardBody,
  Button
} from 'reactstrap'

import ReactTable from 'react-table'

import PageTitle from '../../components/PageTitle/PageTitle'
import StatusModal from './StatusModal'

import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

class Informations extends PureComponent {
  state = {
    showStatusModal: false,
    selectedInformation: null
  }

  componentDidMount () {
    this.props.fetchProperty(this.props.match.params.id)
    this.props.fetchInformations(this.props.match.params.id)
  }

  _handleSelectClick = (e, id) => {
    e.stopPropagation()
    this.setState({ selectedInformation: id, showStatusModal: true })
  }

  _handleCloseStatusModal = () => {
    this.setState({ selectedInformation: null, showStatusModal: false })
  }

  _btnClicked = (e) => {
    return this.props.history.push(`/properties/${this.props.match.params.id}/informations/add`)
  }

  _renderStatusbtn = (status) => {
    switch (status) {
      case 1:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-success">success</div>
            <div>配信済み</div>
          </div>
        )
      case 2:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-info">info</div>
            <div>配信予約</div>
          </div>
        )
      case 3:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-warning">warning</div>
            <div>下書き</div>
          </div>
        )
      default:
        return null
    }
  }

  _renderType = (row) => {
    const { color_code, tag } = row.original
    return (
      <div className="flex_center">
        <div className=" mr-2 badge badge-dot badge-dot-m" style={{ color: ' #fff', backgroundColor: color_code }}>tag_color</div>
        <div>{tag}</div>
      </div>
    )
  }

  _renderConfirmReadStatus = (push_type, id) => {
    if (push_type === 0) return null
    return (
      <Button color="success" className="mt-2" onClick={(e) => this._handleSelectClick(e, id)}>状況確認する</Button>
    )
  }

  _renderTable=() => {
    if (this.props.informations.loading) return <div className="table_center"><Loader type="ball-pulse"/></div> // TODO: add a real loader?
    if (!this.props.informations.data) return null
    return (
      <ReactTable
        data={this.props.informations.data}
        defaultPageSize={10}
        getTdProps={(state, rowInfo) => {
          return {
            onClick: (e) => {
              if (rowInfo !== undefined) {
                return this.props.history.push(`/properties/${this.props.match.params.id}/informations/${rowInfo.original.id}`)
              }
            }
          }
        }}
        columns={[
          {
            Header: '種別',
            accessor: 'tag',
            maxWidth: 110,
            Cell: row => (
              <div>
                {this._renderType(row)}
              </div>
            )
          },
          {
            Header: 'タイトル',
            accessor: 'title'
          },
          {
            Header: '日付',
            accessor: 'date',
            maxWidth: 100,
            className: 'table_center',
            Cell: row => (
              <Fragment>
                {row.value ? moment(new Date(row.value)).format('YYYY/MM/DD') : ''}
              </Fragment>
            )
          },
          {
            Header: 'ステータス',
            accessor: 'status',
            maxWidth: 120,
            Cell: row => (
              <Fragment>
                {this._renderStatusbtn(row.value)}
              </Fragment>
            )
          },
          {
            Header: '開封状況',
            accessor: 'push_type',
            maxWidth: 120,
            Cell: row => (
              <Fragment>
                {this._renderConfirmReadStatus(row.value, row.original.id)}
              </Fragment>
            )
          }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  render () {
    const { property, informations } = this.props
    if (!property.data || !informations.data) return null
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.state.showStatusModal ? <StatusModal id={this.state.selectedInformation} closeModal={this._handleCloseStatusModal} /> : null }
          <div>
            <PageTitle
              heading={`${property.data.name} - 管理情報一覧`}
              icon="pe-7s-info stroke-orange"
              btnSupportedRoles={[0, 1]}
              btnClick = {this._btnClicked}
              btnText = '追加'
              btnColor = "warning"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ property, informations }) => ({
  property,
  informations
})

const mapDispatchToProps = {
  fetchProperty,
  fetchInformations
}

export default connect(mapStateToProps, mapDispatchToProps)(Informations)

Informations.propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  fetchInformations: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  informations: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.oneOf([0, 1, 2]),
      title: PropTypes.string,
      data: PropTypes.string,
      status: PropTypes.oneOf([1, 2, 3])
      // status: PropTypes.oneOf(['配信済み', '配信予約', '下書き'])
    }))
  })
}
Informations.defaultProps = {

}
