import { FETCH_MANAGE_USERS, FETCH_MANAGE_USERS_SUCCESS, FETCH_MANAGE_USERS_FAIL } from '../actions/types'

const INITIAL_STATE = { loading: true, data: null }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MANAGE_USERS:
      return { ...state, loading: true }
    case FETCH_MANAGE_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_MANAGE_USERS_FAIL:
      return { ...state, loading: false }
    default:
      return (state)
  }
}
