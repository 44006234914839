/* eslint-disable */
import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

import MetisMenu from 'react-metismenu'
import RouterLink from 'react-metismenu-router-link'

import { NavItems } from './NavItems';

class Nav extends Component {

    state = {};
    handleSelect = () => {
      this.props.closeMobileSidebar()
    }

    render() {
        return (
            <Fragment>
                <h5 className="app-sidebar__heading">Menu</h5>
                <MetisMenu
                    content={this.props.role === 2 ? NavItems.viewer : NavItems.admin }
                    LinkComponent={RouterLink}
                    // activeLinkFromLocation
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={this.handleSelect}
                />
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

const mapStateToProps = ({ auth }) => ({
  role: auth.user.role
})


export default withRouter(connect(mapStateToProps)(Nav))
