/* eslint-disable */
import React, {PureComponent, Fragment} from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  CustomInput,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import _ from 'lodash'
import { fetchResidents } from './../../actions'
import { connect } from 'react-redux'
import ModalItem from '../ModalItem/ModalItem'
import Loader from 'react-loaders'

import './modal.sass'

class MainModal extends PureComponent {
  state = {
    modal: false,
    selectPrefectureOpen: false,
    prefecture: null,
    selectAddressOpen: false,
    address: null
  }

  componentDidMount = () => {
    this.props.fetchResidents()
  }

  toggle = () => {
    if (this.state.modal) {
      this.setState({
        prefecture: null,
        address: null
      })
    }
    this.setState({
      modal: !this.state.modal
    });

  }

  togglePrefecture = () => {
    this.setState({ selectPrefectureOpen: !this.state.selectPrefectureOpen })
  }
  toggleAddress = () => {
    this.setState({ selectAddressOpen: !this.state.selectAddressOpen })
  }

  _handleSelectPrefecture = (e) => {
    const prefecture = e.target.innerText
    if (prefecture === 'すべて') {
      this.setState({
        prefecture: null,
        address: null
      })
    } else {
      this.setState({ prefecture, address: null })
    }
  }
  _handleSelectAddress = (e) => {
    const address = e.target.innerText === 'すべて' ? null : e.target.innerText
    this.setState({ address })
  }

  _createData = (data) =>{
    const { prefecture, address } = this.state
    const sorted = {}
    data
      .filter(item => {
        const statusFilter = Number(item.status) === 1
        const prefFilter = prefecture ? item.prefecture === prefecture : true
        const addressFilter = address ? item.address1 === address : true
        return statusFilter && prefFilter && addressFilter
      })
      .forEach(item => {
        const {
          id,
          room_number,
          property_id,
          property_name,
          prefecture
        } = item
        if (sorted[property_id]) {
          sorted[property_id] = {
            ...sorted[property_id],
            residents: [...sorted[property_id].residents, {
              id,
              room_number
            }]
          }
        } else {
          sorted[property_id] = {
            property_name,
            prefecture,
            residents: [{
              id,
              room_number
            }]
          }
        }
      })
    return sorted
  }

  _renderItems = () => {
    const { residents, handlePropertyCheck, notification_users } = this.props
    if (residents.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    if (!residents.data) return null
    // console.log('residentsdata:', this.props.residents.data)
    // this._createData(residents.data)
    return(
      _.map(this._createData(residents.data), (value, name)=>{
      // console.log('value:',value,'name:',name)
      const isChecked = () => {
        const ids = []
        value.residents.forEach(resident => {
          ids.push(resident.id)
        })

        return ids.every(id => notification_users.includes(id))
      }
      return (
        <Fragment key={name}>
          {/* {console.log('props:',residents.data,'name:', name)} */}
          <CustomInput type="checkbox" id={name} label={value.property_name} onChange={(e) => handlePropertyCheck(name, e, value.residents)} checked={isChecked()}/>
         {this._renderItem(value.residents)}
        </Fragment>
      )
    })
    )
  }

  _renderItem = (residents) => {
    return (
      _.map(residents, (value, name) => {
        // console.log('name2:',name,'value2:',value)
        return (
          <ModalItem key={value.id} item={value} handleCheck={this.props.handleCheck} notification_users={this.props.notification_users}/>
        )
      })
    )
  }

  _renderPrefectures = () => {
    const { residents } = this.props
    if (residents.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    let prefectures = []
    if (residents) {
      residents.data.forEach(resident => {
        if (!prefectures.includes(resident.prefecture)) {
          prefectures.push(resident.prefecture)
        }
      })
    }
    return prefectures.map(prefecture => {
      return <DropdownItem onClick={this._handleSelectPrefecture} key={prefecture}>{prefecture}</DropdownItem>
    })
  }
  _renderAddresses = () => {
    const { residents } = this.props
    if (residents.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    let addresses = []
    residents.data
      .filter(resident => resident.prefecture === this.state.prefecture)
      .forEach(resident => {
        if (!addresses.includes(resident.address1)) {
          addresses.push(resident.address1)
        }
      })
    return addresses.map(address => {
      return <DropdownItem onClick={this._handleSelectAddress} key={address}>{address}</DropdownItem>
    })
  }

  render() {
    const { modal, selectPrefectureOpen, prefecture, selectAddressOpen, address } = this.state
    return (
      <span className="d-block mb-2">
        <Button color="primary" onClick={this.toggle}>{this.props.modalTitle}</Button>
        <Modal isOpen={modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
          <ModalBody>
            <Form>
              <div className="resident_select select_container">
                <Dropdown isOpen={selectPrefectureOpen} toggle={this.togglePrefecture}>
                  <DropdownToggle caret>{prefecture || '都道府県'}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>都道府県を選択してください</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={this._handleSelectPrefecture}>すべて</DropdownItem>
                    { this._renderPrefectures() }
                  </DropdownMenu>
                </Dropdown>
                {
                  this.state.prefecture
                   ? (
                    <Dropdown isOpen={selectAddressOpen} toggle={this.toggleAddress}>
                      <DropdownToggle caret>{address || '住所'}</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>住所を選択してください</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={this._handleSelectAddress}>すべて</DropdownItem>
                        { this._renderAddresses() }
                      </DropdownMenu>
                    </Dropdown>
                   )
                   : null
                }
              </div>
              <hr/>
              <FormGroup>
                {this._renderItems()}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>閉じる</Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = ({ residents }) => ({
  residents
})

const mapDispatchToProps = {
  fetchResidents
}

export default connect(mapStateToProps, mapDispatchToProps)(MainModal)
