const setURL = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return 'https://sumitomo-prod-api-qcu4ia2elq-an.a.run.app/admin/v1/'
    case 'dev':
    default:
      return 'https://sumitomo-dev-api-qcu4ia2elq-an.a.run.app/admin/v1/'
      // return 'http://localhost:8080/admin/v1/'
  }
}
export const API_URL = setURL()

export const ANDROID_URL = 'https://play.google.com/store/apps/details?id=jp.co.stepon.mms'
export const IOS_URL = 'https://apps.apple.com/us/app/id1498194526'
