/* eslint-disable */
import React, { Fragment } from 'react';
import {
  ListGroup, ListGroupItem
} from 'reactstrap';
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import {Button} from 'reactstrap'

import './DropZone.sass'

class FileInput extends React.Component {
  state = {
    error: ''
  }
  // show image
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.files !== this.props.files && this.props.files) {
  //     const el = document.querySelector('.dropzone-wrapper')
  //     console.log('file', this.props.files);

  //     el.style.backgroundImage = `url(${this.props.files})`
  //   }
  // }

  handleImageChange = (image) => {
    const maxSize = 10 // in MB
    if (image) {
      let reader = new FileReader()

      return new Promise((resolve, reject) => {
        // Check file type
        const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
        if (!acceptedTypes.includes(image.type)) {
          reject(new Error('画像ファイル外を選択しました。\n確認の上でもう一度画像ファイルを選択してください。'))
        }

        // Check file size
        if (image.size > maxSize * 1000000) {
          reject(new Error(`画像ファイルは${maxSize}MBを超えています。\n確認の上でもう一度画像ファイルを選択してください。`))
        }


        reader.onerror = () => {
          reject(new DOMException('Problem parsing input file.'))
        }

        reader.onload = () => {
          resolve({
            image,
            imageUrl: reader.result
          })
        }
        reader.readAsDataURL(image)
      })
    }
  }

  async onDrop(files) {
    // use handleChange here...
    const file = files && files[0]
    try {
      const { imageUrl } = await this.handleImageChange(file)
      if (imageUrl) {
        this.props.handleChange(file, imageUrl)
        this.setState({ error: '' })
      }
    } catch (error) {
      // console.log(error)
      this.setState({ error: error.message })
    }
  }

  onCancel() {
    // use handleChange here...
    // this.props.handleChange(null)
  }

  _handleCancel = () => {
    this.props.handleChange(null)
  }
  _renderCurrentFiles = () => {
    if(this.props.imagePropsUrl){
      return(
        <Fragment>
          <label className="label_maegin">現在の画像</label>
          <ListGroup>
            <ListGroupItem className="dropzone_preview">
              <div className="img_container">
                <img src={this.props.imagePropsUrl} alt="" />
              </div>
              <span>この画像が選択されています。</span>
            </ListGroupItem>
          </ListGroup>
        </Fragment>
      )
    }
  }
  _renderFiles = () => {
    // console.log('image', this.props.imageUrl)
    if(this.props.files && typeof this.props.files !== 'string'){
      return (
        <Fragment>
        <label className="label_margin">選択した画像ファイル</label>
        <ListGroup>
          <ListGroupItem className="dropzone_preview">
            <div className="img_container">
              <img src={this.props.imageUrl} alt=""/>
            </div>
            <span>{this.props.files.name} - {this.props.files.size} bytes</span>
            <span className="cancellBtn"　onClick={this.props.reset}>×</span>
          </ListGroupItem>
        </ListGroup>
        </Fragment>
      )
    }
  }
  _renderError = () => {
    if (!this.state.error) return null

    return <small className="image_error">{this.state.error}</small>
  }

  render() {
    return (
      <Fragment>
        <div className="dropzone-wrapper dropzone-wrapper-lg">
          <Dropzone
            onDrop={this.onDrop.bind(this)}
            onFileDialogCancel={this.onCancel.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-content">
                  <p>アップロードするファイルをドロップ<br />またはここをクリックして追加</p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
          { this._renderError() }
          {this._renderCurrentFiles()}
          {this._renderFiles()}
      </Fragment>
    )
  }
}

export default FileInput

FileInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  file: PropTypes.object
}
