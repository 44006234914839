import React, { PureComponent, Fragment } from 'react'
// import PropTypes from 'prop-types'
import './Group.sass'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import DropZone from '../../components/DropZone/index'
import PageTitle from '../../components/PageTitle/PageTitle'

import ReactTable from 'react-table'
import Loader from 'react-loaders'
import { toast } from 'react-toastify'
import moment from 'moment'

import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/SwipeableTabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import MainModal from '../../components/UserModal/UserModal'
import PropertyModal from '../../components/PropertyModal/PropertyModal'

import {
  Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input
} from 'reactstrap'

import image from '../../assets/utils/images/no_image.jpg'

import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import { connect } from 'react-redux'
import { fetchManageGroup, postManageGroup, deleteManageGroup, resetManageGroup } from '../../actions'

class Group extends PureComponent {
  state = {
    name: '',
    description: '',
    image_url: '',
    properties: [],
    members: [],
    id: ''
  }

  componentDidMount () {
    if (this.props.match.params.id !== undefined) {
      this.props.fetchManageGroup(this.props.match.params.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.manageGroup.data !== this.props.manageGroup.data) {
      this._updateState()
    }
  }

  componentWillUnmount = () => {
    this.props.resetManageGroup()
  }

  _updateState = () => {
    this.setState({
      name: this.props.manageGroup.data.name,
      description: this.props.manageGroup.data.description,
      image: this.props.manageGroup.data.image,
      properties: this.props.manageGroup.data.properties,
      members: this.props.manageGroup.data.members
    })
    if (this.props.manageGroup.data.id) {
      this.setState({ id: this.props.manageGroup.data.id })
    }
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleCancell = (e) => {
    e.preventDefault()
    return this.props.history.push(`/groups`)
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    // prepare data object
    const members = this.state.members.map((key) => {
      return key.id
    })
    const properties = this.state.properties.map((key) => {
      return key.id
    })
    const {
      name,
      description
      // image_url
    } = this.state
    const data = {
      name,
      description,
      properties, // format this property
      members
    }
    if (this.state.id) {
      data.id = this.state.id
    }
    const image = this.state.image

    // validate ?

    // prepare success function
    const success = () => {
      toast.success(
        '保存が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/groups`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '保存に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
    }

    // send data to action
    this.props.postManageGroup(data, image, success, fail)
  }

  _handleImage = (file, image_url) => {
    this.setState({ image: file, image_url })
  }

  _handleDelete = (e) => {
    e.preventDefault()
    // validate ?

    // prepare success function
    const success = () => {
      toast.success(
        '削除が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/groups`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '削除に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
    }

    // send data to action
    const FinalAnswer = window.confirm('本当に"' + this.state.name + '" を削除しますか')
    if (FinalAnswer) {
      this.props.deleteManageGroup(this.props.match.params.id, success, fail)
    }
  }

  _handleUserChange = (user, e) => {
    const { members } = this.state
    const checked = e.target.checked
    if (checked) {
      // if (!members.includes(id)) {
      //   this.setState({ members: [...members, id] })
      // // }
      // console.log('user', user)
      // console.log('members', members)

      if (!members.some(member => member.id === user.id)) {
        this.setState({ members: [...members, user] })
      }

      // console.log('members:', members)
    } else {
      this.setState({ members: members.filter(member => member.id !== user.id) })
      // console.log('members:', members)
    }
    // console.log('changestate', this.state)
  }

  _handlePropertyChange = (property, e) => {
    const { properties } = this.state
    const checked = e.target.checked
    if (checked) {
      // if (!members.includes(id)) {
      //   this.setState({ members: [...members, id] })
      // // }
      // console.log('user', user)
      // console.log('members', members)

      if (!properties.some(member => member.id === property.id)) {
        this.setState({ properties: [...properties, property] })
      }

      // console.log('members:', members)
    } else {
      this.setState({ properties: properties.filter(member => member.id !== property.id) })
      // console.log('members:', members)
    }
    // console.log('changestate', this.state)
  }

  _handleImageDelete = () => {
    this.setState({
      image_url: '',
      image: ''
    })
  }

  _rednerPageTitle = () => {
    const { name } = this.state
    return (
      <PageTitle
        heading={name}
        icon="pe-7s-key stroke-orange"
      />
    )
  }

  _renderImage = (picture) => {
    if (picture) {
      return (
        <img width={28}
          src={picture}
          alt="" />
      )
    } else {
      return (
        <img width={28} height={28} className="rounded-circle"
          src={image}
          alt="" />
      )
    }
  }

  _renderForm = () => {
    const { name, description, image, image_url } = this.state
    return (
      <Fragment>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>グループ名<span className="required">*</span></Label>
              <Input type="text" name="name" id="name" value={name} onChange={this._handleChange} required/>
            </FormGroup>
            <FormGroup>
              <Label>説明</Label>
              <Input type="textarea" name="description" id="name" value={description} onChange={this._handleChange} />
            </FormGroup>
          </Col>
          <Col>
            <Label for="image_url">画像</Label>
            <DropZone
              files={image}
              imageUrl={image_url} // pass image URL or base64 for preview
              handleChange={this._handleImage}
              imagePropsUrl={this.props.manageGroup.data ? this.props.manageGroup.data.image : ''}
              reset={this._handleImageDelete}
            />
            {
              this.props.role === 0
                ? (
                  <Row className="mt-4">
                    <Col>
                      <PropertyModal
                        modalTitle="物件を管理する"
                        handleCheck={this._handlePropertyChange}
                        members={this.state.properties}
                      />
                    </Col>
                    <Col>
                      <MainModal
                        modalTitle="ユーザーを管理する"
                        handleCheck={this._handleUserChange}
                        members={this.state.members}
                      />
                    </Col>
                  </Row>
                )
                : null
            }
          </Col>
        </Row>
      </Fragment>
    )
  }

  _handlePropertyRowClick = (rowInfo, column) => {
    if (rowInfo !== undefined) {
      return this.props.history.push(`/properties/${rowInfo.original.id}`)
    }
  }

  _handleMemberRowClick = (rowInfo, column) => {
    if (rowInfo !== undefined) {
      return this.props.history.push(`/manage_users/${rowInfo.original.id}`)
    }
  }

  _renderType = (type) => {
    switch (type) {
      case 0:
        return '管理者'
      case 1:
        return '編集者'
      case 2:
        return '閲覧者'
      default:
        return ''
    }
  }

  _renderStatus = (status) => {
    if (status === 0) {
      return (
        <div className="flex_center">
          <div className=" mr-2 badge badge-dot badge-dot-m badge-danger">Danger</div>
          <div>無効</div>
        </div>
      )
    } else {
      return (
        <div className="flex_center">
          <div className=" mr-2 badge badge-dot badge-dot-m badge-success">Success</div>
          <div>有効</div>
        </div>
      )
    }
  }

  _renderList = () => {
    const { properties, members } = this.state
    return (
      <Tabs
        defaultActiveKey="1"
        renderTabBar={() => <ScrollableInkTabBar />}
        renderTabContent={() => <TabContent />}
        className="margin_t_10"
      >
        <TabPane tab='物件' key="1">
          <ReactTable
            data={properties}
            defaultPageSize={5}
            getTdProps={(state, rowInfo, column) => {
              return {
                onClick: () => this._handlePropertyRowClick(rowInfo, column)
              }
            }}
            columns={[
              {
                Header: '',
                maxWidth: 45,
                sortable: false,
                accessor: 'image_url',
                Cell: row => (
                  <div>
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                          <div className="widget-content-left">
                            {this._renderImage(row.value)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              },
              {
                Header: '物件名',
                accessor: 'name',
                className: 'table_center'
              },
              {
                Header: '住所',
                accessor: 'prefecture',
                className: 'table_center'
              },
              {
                Header: 'ユーザー',
                accessor: 'user_count',
                className: 'table_center',
                maxWidth: 100
              }
            ]}
            previousText={PREVIOUS_TEXT}
            nextText={NEXT_TEXT}
            loadingText={LOADING_TEXT}
            noDataText={NO_DATA_TEXT}
            pageText={PAGE_TEXT}
            ofText={OF_TEXT}
            rowsText={ROWS_TEXT}
          />
        </TabPane>
        <TabPane tab='ユーザー' key="2">
          <ReactTable
            data={members}
            defaultPageSize={5}
            getTdProps={(state, rowInfo, column) => {
              return {
                onClick: () => this._handleMemberRowClick(rowInfo, column)
              }
            }}
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
                className: 'table_center'
              },
              {
                Header: '名前',
                accessor: 'name',
                className: 'table_center'
              },
              {
                Header: 'グループ',
                accessor: 'group_name',
                className: 'table_center'
              },
              {
                Header: '属性',
                accessor: 'role',
                className: 'table_center',
                Cell: row => (
                  <Fragment>
                    {this._renderType(row.value)}
                  </Fragment>
                )
              },
              {
                Header: '最終ログイン日',
                accessor: 'last_login_date',
                className: 'table_center',
                Cell: row => (
                  <Fragment>
                    {row.value ? moment(row.value).format('YYYY/MM/DD') : 'no login'}
                  </Fragment>
                )
              },
              {
                Header: '状態',
                accessor: 'status',
                className: 'table_center',
                Cell: row => (
                  <Fragment>
                    {this._renderStatus(row.value)}
                  </Fragment>
                )
              }
            ]}
            previousText={PREVIOUS_TEXT}
            nextText={NEXT_TEXT}
            loadingText={LOADING_TEXT}
            noDataText={NO_DATA_TEXT}
            pageText={PAGE_TEXT}
            ofText={OF_TEXT}
            rowsText={ROWS_TEXT}
          />
        </TabPane>
      </Tabs>
    )
  }

  _renderSubmitBtn = () => {
    if (!this.props.manageGroup.posting) {
      return <Button color="success" type="submit" className="mt-2">保存</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderDeleteBtn = () => {
    if (!this.props.manageGroup.deleting) {
      return <Button color="danger" className="mt-2" type="button" onClick={this._handleDelete}>削除</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderBtn = () => {
    if (this.props.role === 1) return null
    if (this.props.match.params.id !== undefined) {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 3 }}>
            <Button outline className=" mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
          <Col md={2}>
            {this._renderDeleteBtn()}
          </Col>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 4 }}>
            <Button outline className="mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
        </Fragment>
      )
    }
  }

  render () {
    const { data, loading } = this.props.manageGroup
    // console.log(this.props.manageGroup)
    if (this.props.match.params.id !== undefined) {
      if (loading) {
        return (
          <div className="table_center maxHeight">
            <Loader type="ball-pulse" />
          </div>
        )
      }
      if (!data) return null
    }
    // console.log('test:', data)
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            {this._rednerPageTitle()}
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <p><span className="required">*</span>は必須項目です。</p>
                  <Form onSubmit={this._handleSubmit}>
                    {this._renderForm()}
                    {this._renderList()}
                    <Row className="margin_t_20">
                      {this._renderBtn()}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
const mapStateToProps = ({ manageGroup, auth }) => ({
  manageGroup,
  role: auth.user.role
})

const mapDispatchToProps = {
  fetchManageGroup, postManageGroup, deleteManageGroup, resetManageGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(Group)

Group.propTypes = {

}
Group.defaultProps = {

}
