/* eslint-disable */
import React, { Fragment } from 'react';
import {connect} from 'react-redux'

import Ionicon from 'react-ionicons';

import PerfectScrollbar from 'react-perfect-scrollbar';
import NoImage from '../../../assets/utils/images/no_image.jpg'

import {
    DropdownToggle, DropdownMenu,
    Nav, Col, Row, Button, NavItem, NavLink,
    UncontrolledTooltip, UncontrolledButtonDropdown
} from 'reactstrap';

import {
    toast,
    Bounce
} from 'react-toastify';

import {
  logoutUser
} from '../../../actions';


import {
    faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    _renderAvater = () =>{
      if (this.props.auth.user.avatar){
        return <img width={42} className="rounded-circle" src={this.props.auth.user.avatar} alt="" />
      } else {
        return <img width={42} className="rounded-circle" src={NoImage} alt="" />
      }
    }


    render() {

        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                  <div className="menu-header-image opacity-2" />
                                  <div className="menu-header-content text-left">
                                    <div className="widget-content p-0">
                                      <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                          {this._renderAvater()}
                                        </div>
                                        <div className="widget-content-left mr-4">
                                          <div className="widget-heading">
                                            {this.props.auth.user.name}
                                          </div>
                                          <div className="widget-subheading opacity-8">
                                            ユーザーID: {this.props.auth.user.id}
                                          </div>
                                        </div>
                                        <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shine" outline color="warning" onClick={this.props.logoutUser}>
                                            ログアウト
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBox)
