export const FETCH_MANAGE_GROUPS = 'all_manageGroups'
export const FETCH_MANAGE_GROUPS_SUCCESS = 'fetch_manageGroups_success'
export const FETCH_MANAGE_GROUPS_FAIL = 'fetch_manageGroups_fail'
export const FETCH_MANAGE_GROUP = 'all_manageGroup'
export const FETCH_MANAGE_GROUP_SUCCESS = 'fetch_manageGroup_success'
export const FETCH_MANAGE_GROUP_FAIL = 'fetch_manageGroup_fail'
export const POST_MANAGE_GROUP = 'post_manageGroup'
export const POST_MANAGE_GROUP_SUCCESS = 'post_manageGroup_success'
export const POST_MANAGE_GROUP_FAIL = 'post_manageGroup_fail'
export const DELETE_MANAGE_GROUP = 'delete_manageGroup'
export const DELETE_MANAGE_GROUP_SUCCESS = 'delete_manageGroup_success'
export const DELETE_MANAGE_GROUP_FAIL = 'delete_manageGroup_fail'
export const RESET_MANAGE_GROUP = 'reset_manage_group'

export const FETCH_MANAGE_USERS = 'all_manageUsers'
export const FETCH_MANAGE_USERS_SUCCESS = 'fetch_manageUsers_success'
export const FETCH_MANAGE_USERS_FAIL = 'fetch_manageUsers_fail'
export const FETCH_MANAGE_USER = 'all_manageUser'
export const FETCH_MANAGE_USER_SUCCESS = 'fetch_manageUser_success'
export const FETCH_MANAGE_USER_FAIL = 'fetch_manageUser_fail'
export const POST_MANAGE_USER = 'post_manageUser'
export const POST_MANAGE_USER_SUCCESS = 'post_manageUser_success'
export const POST_MANAGE_USER_FAIL = 'post_manageUser_fail'
export const DELETE_MANAGE_USER = 'delete_manageUser'
export const DELETE_MANAGE_USER_SUCCESS = 'delete_manageUser_success'
export const DELETE_MANAGE_USER_FAIL = 'delete_manageUser_fail'
export const RESET_MANAGE_USER = 'reset_manage_user'

export const FETCH_INFORMATIONS = 'all_informations'
export const FETCH_INFORMATIONS_SUCCESS = 'fetch_informations_success'
export const FETCH_INFORMATIONS_FAIL = 'fetch_informations_fail'
export const FETCH_INFORMATION = 'all_information'
export const FETCH_INFORMATION_SUCCESS = 'fetch_information_success'
export const FETCH_INFORMATION_FAIL = 'fetch_information_fail'
export const POST_INFORMATION = 'post_information'
export const POST_INFORMATION_SUCCESS = 'post_information_success'
export const POST_INFORMATION_FAIL = 'post_information_fail'
export const DELETE_INFORMATION = 'delete_information'
export const DELETE_INFORMATION_SUCCESS = 'delete_information_success'
export const DELETE_INFORMATION_FAIL = 'delete_information_fail'
export const RESET_INFORMATION = 'reset_information'
export const FETCH_INFORMATION_STATUS = 'fetch_notice_status'
export const FETCH_INFORMATION_STATUS_SUCCESS = 'fetch_notice_status_success'
export const FETCH_INFORMATION_STATUS_FAIL = 'fetch_notice_status_fail'
export const RESET_INFORMATION_STATUS = 'reset_notice_status'
export const FETCH_INFORMATION_TAGS = 'fetch_information_tags'
export const FETCH_INFORMATION_TAGS_SUCCESS = 'fetch_information_tags_success'
export const FETCH_INFORMATION_TAGS_FAIL = 'fetch_information_tags_fail'

export const FETCH_NOTICES = 'all_notices'
export const FETCH_NOTICES_SUCCESS = 'fetch_notices_success'
export const FETCH_NOTICES_FAIL = 'fetch_notices_fail'
export const FETCH_NOTICE = 'all_notice'
export const FETCH_NOTICE_SUCCESS = 'fetch_notice_success'
export const FETCH_NOTICE_FAIL = 'fetch_notice_fail'
export const POST_NOTICE = 'post_notice'
export const POST_NOTICE_SUCCESS = 'post_notice_success'
export const POST_NOTICE_FAIL = 'post_notice_fail'
export const DELETE_NOTICE = 'delete_notice'
export const DELETE_NOTICE_SUCCESS = 'delete_notice_success'
export const DELETE_NOTICE_FAIL = 'delete_notice_fail'
export const RESET_NOTICE = 'reset_notice'
export const FETCH_NOTICE_STATUS = 'fetch_notice_status'
export const FETCH_NOTICE_STATUS_SUCCESS = 'fetch_notice_status_success'
export const FETCH_NOTICE_STATUS_FAIL = 'fetch_notice_status_fail'
export const RESET_NOTICE_STATUS = 'reset_notice_status'

export const FETCH_RESIDENTS = 'all_residents'
export const FETCH_RESIDENTS_SUCCESS = 'fetch_residents_success'
export const FETCH_RESIDENTS_FAIL = 'fetch_residents_fail'
export const BAN_RESIDENTS = 'ban_residents'
export const BAN_RESIDENTS_SUCCESS = 'ban_residents_success'
export const BAN_RESIDENTS_FAIL = 'ban_residents_fail'

export const FETCH_PROPERTIES = 'all_properties'
export const FETCH_PROPERTIES_SUCCESS = 'fetch_properties_success'
export const FETCH_PROPERTIES_FAIL = 'fetch_properties_fail'
export const FETCH_PROPERTY = 'all_property'
export const FETCH_PROPERTY_SUCCESS = 'fetch_property_success'
export const FETCH_PROPERTY_FAIL = 'fetch_property_fail'
export const POST_PROPERTY = 'post_property'
export const POST_PROPERTY_SUCCESS = 'post_property_success'
export const POST_PROPERTY_FAIL = 'post_property_fail'
export const DELETE_PROPERTY = 'delete_property'
export const DELETE_PROPERTY_SUCCESS = 'delete_property_success'
export const DELETE_PROPERTY_FAIL = 'delete_property_fail'
export const RESET_PROPERTY = 'reset_property'

export const LOGIN_USER = 'login_user'
export const LOGIN_USER_SUCCESS = 'login_user_success'
export const LOGIN_USER_FAIL = 'login_user_fail'
export const LOGOUT_USER = 'logout_user'

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE'
export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU'
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL'
export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER'
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW'
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW'
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR'
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR'
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER'
export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON'
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING'
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT'
export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE'
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR'
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME'
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY'
export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR'
