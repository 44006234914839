import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unregister } from './serviceWorker'

import store from './config/configureStore'
import Router from './routes/Router'
import { noop } from './constants'

import 'core-js'
import './assets/base.scss'

unregister()
noop()

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.querySelector('#root')
)

// if (module.hot) {
//   module.hot.accept('./DemoPages/Main', () => {
//     const NextApp = require('./DemoPages/Main').default
//     renderApp(NextApp)
//   })
// }
