/* eslint-disable */
import React, { Fragment } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo/AppLogo';

// import SearchBox from './Components/SearchBox';
// import MegaMenu from './Components/MegaMenu';
import UserBox from './HeaderItem/UserBox';
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";

// import HeaderDots from "./Components/HeaderDots";

class Header extends React.Component {
    render() {
        const {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo />

                    <div className={cx(
                        "app-header__content",
                        { 'header-mobile-open': enableMobileMenuSmall },
                    )}>
                        <div className="app-header-right">
                            <UserBox />
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.theme.enableHeaderShadow,
    closedSmallerSidebar: state.theme.closedSmallerSidebar,
    headerBackgroundColor: state.theme.headerBackgroundColor,
    enableMobileMenuSmall: state.theme.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
