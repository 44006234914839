import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

import { checkAuth } from '../actions'

import Login from '../pages/Login'
import StoreRedirect from '../pages/StoreRedirect'
import App from './App'

class Router extends PureComponent {
  componentDidMount () {
    this.props.checkAuth()
  }

  render () {
    return (
      <BrowserRouter>
        {
          this.props.auth && this.props.auth.token && this.props.auth.user
          // TODO: revert App and Login component
            ? <App />
            : (
              <Switch>
                <Route path="/store" component={StoreRedirect} />
                <Route path="/" component={Login} />
              </Switch>
            )
        }
      </BrowserRouter>
    )
  }
}
// export default Router

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  checkAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(Router)
