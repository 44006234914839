import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAIL,
  POST_PROPERTY,
  POST_PROPERTY_SUCCESS,
  POST_PROPERTY_FAIL,
  DELETE_PROPERTY,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  RESET_PROPERTY
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  deleting: false,
  data: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROPERTY:
      return { ...state, loading: true }
    case FETCH_PROPERTY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_PROPERTY_FAIL:
      return { ...state, loading: false, deleting: false }
    case POST_PROPERTY:
      return { ...state, posting: true, deleting: true }
    case POST_PROPERTY_SUCCESS:
      return { ...state, posting: false, deleting: false }
    case POST_PROPERTY_FAIL:
      return { ...state, posting: false, deleting: false }
    case DELETE_PROPERTY:
      return { ...state, posting: true, deleting: true }
    case DELETE_PROPERTY_SUCCESS:
      return { ...state, posting: false, deleting: false }
    case DELETE_PROPERTY_FAIL:
      return { ...state, posting: false, deleting: false }
    case RESET_PROPERTY:
      return INITIAL_STATE
    default:
      return (state)
  }
}
