import {
  FETCH_NOTICES_SUCCESS,
  FETCH_NOTICES,
  FETCH_NOTICES_FAIL,
  FETCH_NOTICE_STATUS,
  FETCH_NOTICE_STATUS_SUCCESS,
  FETCH_NOTICE_STATUS_FAIL,
  RESET_NOTICE_STATUS
} from './types'

export const fetchNotices = () => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_NOTICES })
  try {
    const res = await api.get(`notices`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_NOTICES_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_NOTICES_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_NOTICES_FAIL })
  }
}

export const fetchNoticeStatus = (id) => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_NOTICE_STATUS })
  try {
    const res = await api.get(`notices/${id}/status`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_NOTICE_STATUS_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_NOTICE_STATUS_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_NOTICE_STATUS_FAIL })
  }
}

export const resetNoticeStatus = () => {
  return { type: RESET_NOTICE_STATUS }
}
