import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from 'react-table'
import Loader from 'react-loaders'
import {
  Row, Col, Button,
  Card, CardBody, CustomInput
} from 'reactstrap'

import { fetchProperties } from '../../actions'
import image from '../../assets/utils/images/no_image.jpg'

import PageTitle from '../../components/PageTitle/PageTitle'
import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import './Properties.sass'

class Properties extends PureComponent {
  state = {
    prefecture: '',
    address: ''
  }

  componentDidMount = () => {
    this.props.fetchProperties()
  }

  _handleAddClick = (e) => {
    return this.props.history.push(`/properties/add`)
  }

  _handleRowClick = (rowInfo, column) => {
    if (rowInfo !== undefined && column.Header !== '管理情報') {
      return this.props.history.push(`/properties/${rowInfo.original.id}`)
    } else if (rowInfo !== undefined && column.Header === '管理情報') {
      return this.props.history.push(`/properties/${rowInfo.original.id}/informations`)
    }
  }

  _handlePrefectureChange = (e) => {
    this.setState({
      prefecture: e.target.value,
      address: ''
    })
  }

  _handleAddressChange = (e) => {
    this.setState({ address: e.target.value })
  }

  _renderImage = (picture) => {
    if (picture) {
      return (
        <img width={28}
          src={picture}
          alt="" />
      )
    } else {
      return (
        <img width={28} height={28} className="rounded-circle"
          src={image}
          alt="" />
      )
    }
  }

  _renderTable = () => {
    if (this.props.properties.loading) {
      return (
        <div className="table_center">
          <Loader type="ball-pulse" />
        </div>
      )
    }
    if (!this.props.properties.data) return null

    const data = this.props.properties.data
      .filter(property => {
        const { prefecture, address } = this.state
        if (!prefecture) {
          return true
        } else {
          if (prefecture === property.prefecture && (!address || address === property.address1)) {
            return true
          }
        }
        return false
      })
      .map((item) => {
        const {
          name,
          image_url,
          prefecture,
          address1,
          address2,
          user_count,
          id
        } = item
        // let address
        // if (prefecture && address1 && address2) {
        //   address = `${prefecture} ${address1} ${address2}`
        // } else {
        //   address = null
        // }
        return {
          name,
          image_url,
          address: `${prefecture} ${address1} ${address2}`,
          user_count,
          id
        }
      })

    return (
      <ReactTable
        data = {data}
        defaultPageSize={10}
        getTdProps={(state, rowInfo, column) => {
          return {
            onClick: () => this._handleRowClick(rowInfo, column)
          }
        }}
        columns={[
          {
            Header: '',
            maxWidth: 45,
            sortable: false,
            accessor: 'image_url',
            Cell: row => (
              <div>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3">
                      <div className="widget-content-left">
                        {this._renderImage(row.value)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          },
          {
            Header: '物件名',
            accessor: 'name'
          },
          {
            Header: '住所',
            accessor: 'address'
          },
          {
            className: 'table_center',
            Header: 'ユーザー',
            accessor: 'user_count',
            maxWidth: 100
          },
          {
            Header: '管理情報',
            accessor: 'id',
            Cell: row => <Button outline color="warning">管理情報</Button>,
            maxWidth: 100
          }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  _renderPrefOptions = () => {
    const { data } = this.props.properties
    if (!data || data.length === 0) return null

    const prefectures = []
    data.forEach(property => {
      if (!prefectures.includes(property.prefecture)) prefectures.push(property.prefecture)
    })

    return prefectures.map((prefecture, i) => {
      return <option key={i} value={prefecture}>{prefecture}</option>
    })
  }

  _renderCityOptions = () => {
    const { data } = this.props.properties
    if (!data || data.length === 0) return null

    const addresses = []
    data.forEach(property => {
      if (!addresses.includes(property.address1) && property.prefecture === this.state.prefecture) addresses.push(property.address1)
    })

    return addresses.map((address, i) => {
      return <option key={i} value={address}>{address}</option>
    })
  }

  render () {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <PageTitle
              heading="物件一覧"
              icon="pe-7s-home stroke-orange"
              btnSupportedRoles={[0, 1]}
              btnClick={this._handleAddClick}
              btnText = '追加'
              btnColor= 'warning'
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col className="prefecture_select">
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        name="customSelect"
                        defaultValue={this.state.prefecture}
                        onChange={this._handlePrefectureChange}
                      >
                        <option value="">全ての都道府県</option>
                        { this._renderPrefOptions() }
                      </CustomInput>
                    </Col>
                    {
                      this.state.prefecture
                        ? (
                          <Col>
                            <CustomInput
                              type="select"
                              id="exampleCustomSelect"
                              name="customSelect"
                              defaultValue={this.state.address}
                              onChange={this._handleAddressChange}
                            >
                              <option value="">全ての市区町村</option>
                              { this._renderCityOptions() }
                            </CustomInput>
                          </Col>
                        )
                        : null
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>

    )
  }
}

const mapStateToProps = ({ properties }) => ({
  properties
})

const mapDispatchToProps = {
  fetchProperties
}

export default connect(mapStateToProps, mapDispatchToProps)(Properties)

Properties.propTypes = {
  fetchProperties: PropTypes.func,
  properties: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefecture: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      user_count: PropTypes.string,
      image_url: PropTypes.string
    }))
  }),
  history: PropTypes.object
}
Properties.defaultProps = {
  title: ''
}
