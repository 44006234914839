import {
  FETCH_MANAGE_USER,
  FETCH_MANAGE_USER_SUCCESS,
  FETCH_MANAGE_USER_FAIL,
  POST_MANAGE_USER,
  POST_MANAGE_USER_SUCCESS,
  POST_MANAGE_USER_FAIL,
  DELETE_MANAGE_USER,
  DELETE_MANAGE_USER_SUCCESS,
  DELETE_MANAGE_USER_FAIL,
  RESET_MANAGE_USER
} from './types'

export const fetchManageUser = (id) => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_MANAGE_USER })
  try {
    const res = await api.get(`members/${id}`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_MANAGE_USER_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_MANAGE_USER_FAIL })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_MANAGE_USER_FAIL })
  }
}

export const postManageUser = (data, image, success, fail) => async (dispatch, getState, api) => {
  dispatch({ type: POST_MANAGE_USER })
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    })
    // formData.append('data', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }

    const res = await api.post(`members`, formData, config)
    if (res.data.success) {
      dispatch({ type: POST_MANAGE_USER_SUCCESS })
      if (success) success()
    } else {
      dispatch({ type: POST_MANAGE_USER_FAIL })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({ type: POST_MANAGE_USER_FAIL })
    if (fail) fail()
  }
}

export const deleteManageUser = (id, success, fail) => async (dispatch, getState, api) => {
  dispatch({ type: DELETE_MANAGE_USER })
  try {
    const res = await api.delete(`members/${id}`)
    if (res.data.success) {
      dispatch({ type: DELETE_MANAGE_USER_SUCCESS })
      if (success) success()
    } else {
      dispatch({ type: DELETE_MANAGE_USER_FAIL })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({ type: DELETE_MANAGE_USER_FAIL })
    if (fail) fail()
  }
}

export const resetManageUser = () => {
  return { type: RESET_MANAGE_USER }
}
