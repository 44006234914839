import {
  FETCH_MANAGE_GROUP,
  FETCH_MANAGE_GROUP_SUCCESS,
  FETCH_MANAGE_GROUP_FAIL,
  POST_MANAGE_GROUP,
  POST_MANAGE_GROUP_SUCCESS,
  POST_MANAGE_GROUP_FAIL,
  DELETE_MANAGE_GROUP_SUCCESS,
  DELETE_MANAGE_GROUP,
  DELETE_MANAGE_GROUP_FAIL,
  RESET_MANAGE_GROUP
} from './types'

export const fetchManageGroup = (id) => async (dispatch, getState, api) => {
  dispatch({
    type: FETCH_MANAGE_GROUP
  })
  try {
    const res = await api.get(`groups/${id}`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_MANAGE_GROUP_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({
        type: FETCH_MANAGE_GROUP_FAIL
      })
    }
  } catch (error) {
    console.warn(error)
    dispatch({
      type: FETCH_MANAGE_GROUP_FAIL
    })
  }
}

export const postManageGroup = (data, image, success, fail) => async (dispatch, getState, api) => {
  dispatch({
    type: POST_MANAGE_GROUP
  })
  try {
    const config = {
      headers: {
        'Content-Type': 'application/JSON'
      }
    }

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    })
    // formData.append('data', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }

    const res = await api.post(`groups`, formData, config)
    if (res.data.success) {
      dispatch({
        type: POST_MANAGE_GROUP_SUCCESS
      })
      if (success) success()
    } else {
      dispatch({
        type: POST_MANAGE_GROUP_FAIL
      })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: POST_MANAGE_GROUP_FAIL
    })
    // if (fail) fail()
  }
}

export const deleteManageGroup = (id, success, fail) => async (dispatch, getState, api) => {
  dispatch({
    type: DELETE_MANAGE_GROUP
  })
  try {
    const res = await api.delete(`groups/${id}`)
    if (res.data.success) {
      dispatch({
        type: DELETE_MANAGE_GROUP_SUCCESS
      })
      if (success) success()
    } else {
      dispatch({
        type: DELETE_MANAGE_GROUP_FAIL
      })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: DELETE_MANAGE_GROUP_FAIL
    })
    if (fail) fail()
  }
}

export const resetManageGroup = () => {
  return { type: RESET_MANAGE_GROUP }
}
