import React, { memo, useEffect } from 'react'

import {
  ANDROID_URL,
  IOS_URL,
  isIOS
} from '../constants'

const StoreRedirect = () => {
  useEffect(() => {
    const url = isIOS() ? IOS_URL : ANDROID_URL
    window.location.replace(url)
  }, [])
  return (
    <p>リダイレクト中、しばらくお待ち下さい...</p>
  )
}

export default memo(StoreRedirect)
