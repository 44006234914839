import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAIL,
  POST_PROPERTY,
  POST_PROPERTY_SUCCESS,
  POST_PROPERTY_FAIL,
  DELETE_PROPERTY,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  RESET_PROPERTY
} from './types'

export const fetchProperty = (id) => async (dispatch, getState, api) => {
  dispatch({
    type: FETCH_PROPERTY
  })
  try {
    const res = await api.get(`properties/${id}`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_PROPERTY_SUCCESS,
        payload: res.data.data
      })
    }
  } catch (error) {
    console.warn(error)
    dispatch({
      type: FETCH_PROPERTY_FAIL
    })
  }
}

export const postProperty = (data, image, success, fail) => async (dispatch, getState, api) => {
  dispatch({ type: POST_PROPERTY })
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    })
    // formData.append('data', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }

    const res = await api.post(`properties`, formData, config)
    if (res.data.success) {
      dispatch({ type: POST_PROPERTY_SUCCESS })
      if (success) success()
    } else {
      dispatch({ type: POST_PROPERTY_FAIL })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: POST_PROPERTY_FAIL
    })
  }
}

export const deleteProperty = (id, success, fail) => async (dispatch, getState, api) => {
  dispatch({ type: DELETE_PROPERTY })
  try {
    const res = await api.delete(`properties/${id}`)
    if (res.data.success) {
      dispatch({ type: DELETE_PROPERTY_SUCCESS })
      if (success) success()
    } else {
      dispatch({ type: DELETE_PROPERTY_FAIL })
      if (fail) fail(res.data.message)
    }
  } catch (error) {
    dispatch({
      type: DELETE_PROPERTY_FAIL
    })
  }
}

export const resetProperty = () => {
  return { type: RESET_PROPERTY }
}
