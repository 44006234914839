import React, { memo } from 'react'
import { CustomInput } from 'reactstrap'

const ModalItem = ({ item, handleCheck, notification_users }) => {
  const _handleCheck = (e) => {
    handleCheck(item.id, e)
  }
  const { id, room_number } = item
  return (
    <CustomInput className="ml-4" type="checkbox" id={`resident_${id}`} label={`${room_number}号室`} onChange={_handleCheck} checked={notification_users.includes(id)} />
  )
}

export default memo(ModalItem)
