import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Button, Form, FormGroup, Input } from 'reactstrap'
import BlockUi from 'react-block-ui'
import PropTypes from 'prop-types'

import { loginUser } from '../../actions'

import './Login.sass'

class Login extends PureComponent {
  state = {
    email: '',
    password: ''
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    this.props.loginUser(this.state.email, this.state.password)
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _renderError = () => {
    return this.props.auth.error ? <div className="required">*Eメールアドレスまたは、パスワードが違います。</div> : null
  }

  render () {
    const { loading } = this.props.auth
    return (
      <Fragment>
        <div className="h-100 bg-plum-plate bg-animation login">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div className="app-logo-inverse mx-auto mb-3"/>
              <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                  <BlockUi tag="div" blocking={loading}>
                    <div className="modal-body">
                      <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                          <div>ログイン</div>
                          <span>Eメールアドレスとパスワードを入力してください。</span>
                        </h4>
                      </div>

                      <Form id="login_form" onSubmit={this._handleSubmit}>
                        <Row form>
                          <Col md={12}>
                            <FormGroup>
                              <Input
                                type="email"
                                name="email"
                                id="exampleEmail"
                                placeholder="Email here..."
                                onChange={this._handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Input
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder="Password here..."
                                onChange={this._handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      {this._renderError()}
                    </div>
                    <div className="modal-footer clearfix">
                      <div className="float-right">
                        <Button type="submit" form="login_form" color="primary" size="lg" onClick={this._handleSubmit}>ログイン</Button>
                      </div>
                    </div>
                  </BlockUi>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired
}
Login.defaultProps = {

}
