import React, { PureComponent, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from 'reactstrap'
import _ from 'lodash'
import { toast } from 'react-toastify'

class GroupModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      member_id: []
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    })
    this._initialMemberSetting(this.props.groupData.value)
  }

  _initialMemberSetting = (groupId) => {
    let members = []
    this.props.data.forEach(item => {
      const { id, group_id } = item
      if (groupId === group_id) {
        members = [...members, id]
      }
    })
    this.setState({ member_id: members })
  }

  _createData = (data) => {
    const sorted = {}
    data.forEach(item => {
      const {
        id,
        name
      } = item
      sorted[id] = {
        name
      }
    })
    return sorted
  }

  _handleClick = (e, id) => {
    const { member_id } = this.state
    const checked = e.target.checked
    if (checked) {
      if (!member_id.includes(id)) {
        this.setState({ member_id: [...member_id, Number(id)] })
      }
    } else {
      this.setState({ member_id: member_id.filter(n => n !== Number(id)) })
    }
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    // prepare data object
    const members = this.state.member_id
    const {
      id,
      name
    } = this.props.groupData.row
    const data = {
      id,
      name,
      members
    }
    const image = undefined

    // validate ?

    // prepare success function
    const success = () => {
      toast.success(
        '保存が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      // this.toggle
      this.props.getFunction()
      this.props.getUsersFunction()
      return this.toggle
    }

    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '保存に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
    }

    // send data to action
    this.props.function(data, image, success, fail)
  }

  _renderItems = () => {
    const { data } = this.props
    // console.log('residentsdata:', this.props.residents.data)
    this._createData(data)
    return (
      _.map(this._createData(data), (value, name) => {
        return (
          <Fragment key={name}>
            <CustomInput className="ml-4" type="checkbox" id={name} label={ value.name } onChange={(e) => this._handleClick(e, name)} checked={ this.state.member_id.includes(Number(name)) }/>
          </Fragment>
        )
      })
    )
  }

  render () {
    if (!this.props.data) return <Button outline onClick={null}>読み込み中</Button>
    return (
      <Fragment>
        <Button color="primary" onClick={this.toggle}>{this.props.modalTitle}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
          <ModalBody>
            {this._renderItems()}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>キャンセル</Button>
            <Button color="primary" onClick={this._handleSubmit}>決定</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default GroupModal
