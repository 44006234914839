/* eslint-disable */
import React, { memo } from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'

import './Breadcrumbs.sass'

const routes = [
  { path: /^\//,
    exact: true,
    name:'物件一覧'
  },
  { path: /^\/properties\/add$/,
    exact: true,
    name:'物件追加'
  },
  { path: /^\/properties\/\d+/,
    exact: true,
    name:'物件詳細'
  },
  { path: /^\/notices/,
    exact: true,
    name:'お知らせ一覧'
  },
  { path: /^\/notices\/add$/,
    exact: true,
    name:'お知らせ追加'
  },
  { path: /^\/notices\/\d+$/,
    exact: true,
    name:'お知らせ詳細'
  },
  { path: /^\/properties\/\d+\/informations/,
    exact: true,
    name:'管理情報一覧'
  },
  { path: /^\/properties\/\d+\/informations\/add$/,
    exact: true,
    name:'管理情報追加'
  },
  { path: /^\/properties\/\d+\/informations\/\d+$/,
    exact: true,
    name:'管理情報詳細'
  },
  { path: /^\/residents/,
    exact: true,
    name:'入居者一覧'
  },
  { path: /^\/manage_users/,
    exact: true,
    name:'ユーザー一覧'
  },
  { path: /^\/manage_users\/add$/,
    exact: true,
    name:'ユーザー追加'
  },
  { path: /^\/manage_users\/\d+$/,
    exact: true,
    name:'ユーザー詳細'
  },
  { path: /^\/groups/,
    exact: true,
    name:'グループ一覧'
  },
  { path: /^\/groups\/add$/,
    exact: true,
    name:'グループ追加'
  },
  { path: /^\/groups\/\d+$/,
    exact: true,
    name:'グループ詳細'
  }
]

const Breadcrumbs = ({ location }) => {
  const createBreacrumbs = () => {
    let breadcrumbs = []
    routes.forEach(route => {
      const { name, path } = route
      if (path.test(location.pathname)) {
        const found = location.pathname.match(path)
        breadcrumbs.push({ name, path: found[0] })
      }
    })
    return breadcrumbs
  }
  const breadcrumbs = createBreacrumbs()

  return (
    <div>
      <Breadcrumb>
        {
          breadcrumbs.map((breadcrumb, i) => {
            if (i === breadcrumbs.length - 1 && breadcrumbs.length > 1) {
              return (
                <BreadcrumbItem key={i}>{breadcrumb.name}</BreadcrumbItem>
              )
            }
            return (
              <BreadcrumbItem  key={i}>
                <NavLink to={breadcrumb.path}>{breadcrumb.name}</NavLink>
              </BreadcrumbItem>
            )
          })
        }
      </Breadcrumb>
    </div>
  )
}

export default withRouter(memo(Breadcrumbs))
