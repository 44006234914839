import React, { PureComponent, Fragment } from 'react'
// import PropTypes from 'prop-types'
import './Notice.sass'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import Loader from 'react-loaders'
import { toast } from 'react-toastify'
import moment from 'moment'

import { fetchNotice, fetchProperties, postNotice, deleteNotice, resetNotice } from '../../actions'

import DropZone from '../../components/DropZone/index'
import PageTitle from '../../components/PageTitle/PageTitle'
import MainModal from '../../components/Modal/Modal'

import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, CustomInput, Input, InputGroup } from 'reactstrap'

class Notice extends PureComponent {
  state={
    id: '',
    title: '',
    content: '',
    date: '',
    image: '',
    url: '',
    video_url: '',
    push_type: 0,
    notification_users: []
  }

  componentDidMount () {
    if (this.props.match.params.id !== undefined) {
      this.props.fetchNotice(this.props.match.params.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.notice.data !== this.props.notice.data) {
      this._updateState()
    }
  }

  componentWillUnmount = () => {
    this.props.resetNotice()
  }

  _updateState = () => {
    const {
      id,
      push_type,
      title,
      content,
      url,
      video_url,
      image,
      date
    } = this.props.notice.data

    const notification_users = []
    this.props.notice.data.notification_users.forEach(property => {
      property.users.forEach(user => {
        if (user.id) {
          notification_users.push(user.id)
        }
      })
    })

    this.setState({
      id,
      push_type: push_type || 0,
      title,
      content,
      url,
      video_url,
      image,
      date,
      // date: date ? moment.unix(date).format('YYYY/MM/DD h:mm a') : moment.format('YYYY / MM / DD h: mm a'),
      notification_users
    })
    // switch (this.props.notice.data.push_type) {
    //   case 1:
    //     this.setState({ push_type: 'push_1' })
    //     break
    //   case 2:
    //     this.setState({ push_type: 'push_2' })
    //     break
    //   default:
    //     this.setState({ push_type: 'push_0' })
    //     break
    // }
  }

  _handleResidentChange = (id, e) => {
    const { notification_users } = this.state
    const checked = e.target.checked
    if (checked) {
      if (!notification_users.includes(id)) {
        this.setState({ notification_users: [...notification_users, id] })
      }
    } else {
      this.setState({ notification_users: notification_users.filter(n => n !== id) })
    }
  }

  _handlePropertyCheck = (id, e, residents) => {
    const { notification_users } = this.state
    const checked = e.target.checked
    // Get all residents id with property_id === id
    const residentIds = residents.map(resident => resident.id)
    // if checked, add the ids in the array (be careful of duplicates id)
    let newResidents = []
    if (checked) {
      residentIds.forEach(id => {
        if (!notification_users.includes(id)) {
          newResidents.push(id)
        }
      })

      this.setState({ notification_users: [...notification_users, ...newResidents] })
    } else {
      // if not checked, remove the ids from the array (be careful of the ids that are not inside the array)
      newResidents = notification_users.filter(id => !residentIds.includes(id))
      this.setState({ notification_users: [...newResidents] })
    }
    // update the state with new ids array
  }

  _handleImage = (file, image_url) => {
    this.setState({ image: file, image_url })
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _radioChange = (e) => {
    this.setState({ [e.target.name]: e.target.id })
  }

  _handleChangeDate = (date) => {
    this.setState({
      date: date
    })
  }

  _handleCancell = (e) => {
    e.preventDefault()
    return this.props.history.push(`/notices`)
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    if (this.props.role === 2) return
    // prepare data object
    const {
      id,
      image,
      push_type,
      title,
      content,
      url,
      video_url,
      date,
      notification_users
    } = this.state

    if (notification_users.length === 0) {
      toast.warn(
        '配信先を選択してください。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return
    }

    const data = {
      push_type,
      title,
      content,
      url,
      video_url,
      date: date ? moment(date, 'yyyy/MM/dd aa h:mm').format('x') : '',
      notification_users
    }

    if (id) {
      data.id = id
    }

    // prepare success function
    const success = () => {
      toast.success(
        '保存が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/notices`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '保存に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.warn(message)
    }

    // send data to action
    this.props.postNotice(data, image, success, fail)
  }

  _handleDelete = (e) => {
    e.preventDefault()
    const success = () => {
      toast.success(
        '削除が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/notices`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '削除に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.log(message)
    }

    // send data to action
    const FinalAnswer = window.confirm('本当に"' + this.state.title + '" を削除しますか')
    if (FinalAnswer) {
      this.props.deleteNotice(this.props.match.params.id, success, fail)
    }
  }

  _handleImageDelete = () => {
    this.setState({
      image_url: '',
      image: ''
    })
  }

  _renderTitle = () => {
    const { title } = this.state
    return (
      <PageTitle
        heading={title}
        icon="pe-7s-bell stroke-orange"
      />
    )
  }

  _renderDatePicker = () => {
    return (
      <InputGroup>
        <DatePicker className="form-control text_center"
          minDate={new Date()}
          dateFormat="yyyy/MM/dd aa h:mm"
          selected={this.state.date}
          onChange={this._handleChangeDate}
          showTimeSelect
          timeFormat="h:mm"
        />
      </InputGroup>
    )
  }

  _renderDate = () => {
    if (Number(this.state.push_type) === 2) {
      return (
        <FormGroup>
          <Label for="date">通知日</Label>
          {this._renderDatePicker()}
        </FormGroup>
      )
    }
  }

  _renderSubmitBtn = () => {
    if (!this.props.notice.posting) {
      return <Button color="success" type="submit" className="mt-2">保存</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderDeleteBtn = () => {
    if (!this.props.notice.deleting) {
      return <Button color="danger" className="mt-2" type="button" onClick={this._handleDelete}>削除</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderBtn = () => {
    if (this.props.role === 2) return null
    if (this.props.match.params.id !== undefined) {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 3 }}>
            <Button outline className=" mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
          <Col md={2}>
            {this._renderDeleteBtn()}
          </Col>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 4 }}>
            <Button outline className="mb-2 mr-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
        </Fragment>
      )
    }
  }

  _renderForm = () => {
    const {
      push_type,
      title,
      content,
      url,
      video_url
    } = this.state
    return (
      <Form onSubmit={this._handleSubmit}>
        <FormGroup>
          <Label for="title">タイトル<span className="required">*</span></Label>
          <Input type="text" name="title" id="title" value={title} onChange={this._handleChange} required/>
        </FormGroup>
        <FormGroup>
          <Label for="content">内容<span className="required">*</span></Label>
          <Input type="textarea" name="content" id="content" value={content} onChange={this._handleChange} required/>
        </FormGroup>
        <FormGroup>
          <Label for="url">URL</Label>
          <Input type="text" name="url" id="url" value={url} onChange={this._handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="video_url">動画URL</Label>
          <Input type="text" name="video_url" id="video_url" value={video_url} onChange={this._handleChange} />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>Push通知設定 <span className="required">*</span></Label>
              <Row>
                <Col>
                  <CustomInput type="radio" id="0" name="push_type" checked={Number(push_type) === 0} onChange={this._radioChange} label="通知無し" />
                </Col>
                <Col>
                  <CustomInput type="radio" id="1" name="push_type" checked={Number(push_type) === 1} onChange={this._radioChange} label="即時" />
                </Col>
                <Col>
                  <CustomInput type="radio" id="2" name="push_type" checked={Number(push_type) === 2} onChange={this._radioChange} label="日時指定" />
                </Col>
              </Row>
            </FormGroup>
            {this._renderDate()}
            <FormGroup>
              <Label>配信先<span className="required">*</span></Label>
              <MainModal
                modalTitle = "配信先を選択する"
                modalContent = 'test'
                handleCheck = {this._handleResidentChange}
                handlePropertyCheck={this._handlePropertyCheck}
                notification_users={this.state.notification_users}
                className="notice_residents_modal"
              />
            </FormGroup>
          </Col>
          <Col>
            <Label for="image_url">画像</Label>
            <DropZone
              files={this.state.image}
              imageUrl={this.state.image_url} // pass image URL or base64 for preview
              handleChange={this._handleImage}
              imagePropsUrl={this.props.notice.data ? this.props.notice.data.image : ''}
              reset={this._handleImageDelete}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          {this._renderBtn()}
        </Row>
      </Form>
    )
  }

  render () {
    const { data, loading } = this.props.notice
    if (this.props.match.params.id !== undefined) {
      if (loading) return <div className="table_center maxHeight"><Loader type="ball-pulse" /></div>
      if (!data) return null
    }

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            {this._renderTitle()}
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <p><span className="required">*</span>は必須項目です。</p>
                  {this._renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ notice, properties, auth }) => ({
  notice, properties, role: auth.user.role
})

const mapDispatchToProps = {
  fetchNotice, fetchProperties, postNotice, deleteNotice, resetNotice
}
export default connect(mapStateToProps, mapDispatchToProps)(Notice)

Notice.propTypes = {

}
Notice.defaultProps = {

}
