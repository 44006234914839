export const TOKEN_NAME = 'sumitomo_token'
export const IDENTITY_NAME = 'sumitomo_identity'

export const PREVIOUS_TEXT = '前のページへ'
export const NEXT_TEXT = '次のページへ'
export const LOADING_TEXT = 'ローディング…'
export const NO_DATA_TEXT = 'データがありません。'
export const PAGE_TEXT = 'ページ'
export const OF_TEXT = '/'
export const ROWS_TEXT = '行'
