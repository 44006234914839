import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { fetchManageUsers } from '../../actions'
import {
  Row, Col,
  Card, CardBody
} from 'reactstrap'
import Loader from 'react-loaders'
import moment from 'moment'

import ReactTable from 'react-table'

import PageTitle from '../../components/PageTitle/PageTitle'

import './ManageUsers.sass'
import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

class ManageUsers extends PureComponent {
  componentDidMount () {
    this.props.fetchManageUsers()
  }

  _btnClicked = (e) => {
    return this.props.history.push(`/manage_users/add`)
  }

  _renderType = (type) => {
    switch (type) {
      case 0:
        return '管理者'
      case 1:
        return '編集者'
      case 2:
        return '閲覧者'
      default:
        return ''
    }
  }

  _renderStatus = (status) => {
    if (status === 0) {
      return (
        <div className="flex_center">
          <div className=" mr-2 badge badge-dot badge-dot-m badge-danger">Danger</div>
          <div>無効</div>
        </div>
      )
    } else {
      return (
        <div className="flex_center">
          <div className=" mr-2 badge badge-dot badge-dot-m badge-success">Success</div>
          <div>有効</div>
        </div>
      )
    }
  }

  _renderTable = () => {
    if (this.props.manageUsers.loading) return <div className="table_center"><Loader type="ball-pulse"/></div> // TODO: add a real loader?
    if (!this.props.manageUsers.data) return null
    return (
      <ReactTable
        data={this.props.manageUsers.data}
        defaultPageSize={10}
        getTdProps={(state, rowInfo) => {
          return {
            onClick: (e) => {
              if (rowInfo !== undefined) {
                return this.props.history.push(`/manage_users/${rowInfo.original.id}`)
              }
            }
          }
        }}
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
            maxWidth: 110,
            className: 'table_center'
          },
          {
            Header: '名前',
            accessor: 'name',
            className: 'table_center'
          },
          {
            Header: 'グループ名',
            accessor: 'group_name',
            className: 'table_center'
          },
          {
            Header: '属性',
            accessor: 'role',
            Cell: row => (
              <Fragment>
                {this._renderType(row.value)}
              </Fragment>
            ),
            className: 'table_center'
          },
          {
            Header: '最終ログイン日',
            accessor: 'last_login_date',
            className: 'table_center',
            Cell: row => (
              <Fragment>
                {row.value ? moment(row.value).format('YYYY/MM/DD') : '未ログイン'}
              </Fragment>
            )
          }
          // {
          //   Header: '利用停止',
          //   accessor: 'status',
          //   maxWidth: '100',
          //   className: 'table_center',
          //   Cell: row => (
          //     <Fragment>
          //       {this._renderStatus(row.value)}
          //     </Fragment>
          //   )
          // }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  render () {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <PageTitle
              heading="管理ユーザー一覧"
              icon="pe-7s-key stroke-orange"
              btnSupportedRoles={[0]}
              btnClick = {this._btnClicked}
              btnText = "追加"
              btnColor = "warning"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ manageUsers }) => ({
  manageUsers
})

const mapDispatchToProps = {
  fetchManageUsers
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)

ManageUsers.propTypes = {
  fetchManageUsers: PropTypes.func,
  manageUsers: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      group_name: PropTypes.string,
      type: PropTypes.oneOf([0, 1, 2]),
      last_login: PropTypes.string,
      status: PropTypes.oneOf([0, 1])
    }))
  })
}
ManageUsers.defaultProps = {

}
