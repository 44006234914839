/* eslint-disable */
export const NavItems = {
  viewer: [
    {
        icon: 'pe-7s-home',
        label: '物件',
        to: '/'
    },
    {
        icon: 'pe-7s-bell',
        label: 'お知らせ',
        to: '/notices'
    },
    {
        icon: 'pe-7s-user',
        label: '入居者',
        to: '/residents'
    }
  ],
  admin: [
    {
        icon: 'pe-7s-home',
        label: '物件',
        to: '/'
    },
    {
        icon: 'pe-7s-bell',
        label: 'お知らせ',
        to: '/notices'
    },
    {
        icon: 'pe-7s-user',
        label: '入居者',
        to: '/residents'
    },
    {
        icon: 'pe-7s-key',
        label: '管理ユーザー',
        content: [
            {
                label: 'ユーザー',
                to: '/manage_users'
            },
            {
                label: 'グループ',
                to:'/groups'
            }
        ]
    }
  ]
}
