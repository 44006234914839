import { FETCH_PROPERTIES, FETCH_PROPERTIES_SUCCESS } from './types'

// const data = [{
//   id: 1,
//   name: '藤巻レジデンス',
//   address: '87766 Onsgard Lane',
//   user_count: 1,
//   image: 'http://dummyimage.com/224x170.png/ff4444/ffffff'
// }, {
//   id: 2,
//   name: 'Mycat',
//   address: '740 Eagan Pass',
//   user_count: 2,
//   image: 'http://dummyimage.com/159x126.bmp/ff4444/ffffff'
// }, {
//   id: 3,
//   name: 'Izio',
//   address: '4 Elka Crossing',
//   user_count: 3,
//   image: 'http://dummyimage.com/157x249.bmp/dddddd/000000'
// }, {
//   id: 4,
//   name: 'Zoozzy',
//   address: '4719 Towne Circle',
//   user_count: 4,
//   image: 'http://dummyimage.com/165x146.png/5fa2dd/ffffff'
// }, {
//   id: 5,
//   name: 'Gigabox',
//   address: '979 Gulseth Street',
//   user_count: 5,
//   image: 'http://dummyimage.com/129x234.jpg/cc0000/ffffff'
// }, {
//   id: 6,
//   name: 'Feednation',
//   address: '569 Vera Parkway',
//   user_count: 6,
//   image: 'http://dummyimage.com/113x220.png/ff4444/ffffff'
// }, {
//   id: 7,
//   name: 'DabZ',
//   address: '29797 Spaight Street',
//   user_count: 7,
//   image: 'http://dummyimage.com/210x152.bmp/dddddd/000000'
// }, {
//   id: 8,
//   name: 'Twimm',
//   address: '5133 Nevada Point',
//   user_count: 8,
//   image: 'http://dummyimage.com/210x107.jpg/5fa2dd/ffffff'
// }, {
//   id: 9,
//   name: 'Skilith',
//   address: '94 South Crossing',
//   user_count: 9,
//   image: 'http://dummyimage.com/191x121.bmp/5fa2dd/ffffff'
// }, {
//   id: 10,
//   name: 'Buzzbean',
//   address: '86 Quincy Pass',
//   user_count: 10,
//   image: 'http://dummyimage.com/119x159.jpg/ff4444/ffffff'
// }, {
//   id: 11,
//   name: 'Realcube',
//   address: '64827 Northland Court',
//   user_count: 11,
//   image: 'http://dummyimage.com/191x244.png/ff4444/ffffff'
// }, {
//   id: 12,
//   name: 'Lazz',
//   address: '38891 Cherokee Street',
//   user_count: 12,
//   image: 'http://dummyimage.com/227x170.jpg/5fa2dd/ffffff'
// }, {
//   id: 13,
//   name: 'Flipopia',
//   address: '968 Atwood Center',
//   user_count: 13,
//   image: 'http://dummyimage.com/193x117.png/ff4444/ffffff'
// }, {
//   id: 14,
//   name: 'Kazio',
//   address: '8 Rockefeller Way',
//   user_count: 14,
//   image: 'http://dummyimage.com/248x115.png/5fa2dd/ffffff'
// }, {
//   id: 15,
//   name: 'Ainyx',
//   address: '379 Anniversary Alley',
//   user_count: 15,
//   image: 'http://dummyimage.com/155x146.jpg/cc0000/ffffff'
// }, {
//   id: 16,
//   name: 'Pixonyx',
//   address: '45 Dapin Pass',
//   user_count: 16,
//   image: 'http://dummyimage.com/170x238.jpg/5fa2dd/ffffff'
// }, {
//   id: 17,
//   name: 'Fivebridge',
//   address: '9 Charing Cross Parkway',
//   user_count: 17,
//   image: 'http://dummyimage.com/162x155.jpg/cc0000/ffffff'
// }, {
//   id: 18,
//   name: 'Viva',
//   address: '1591 Linden Lane',
//   user_count: 18,
//   image: 'http://dummyimage.com/205x249.bmp/ff4444/ffffff'
// }, {
//   id: 19,
//   name: 'Photofeed',
//   address: '4341 Crest Line Hill',
//   user_count: 19,
//   image: 'http://dummyimage.com/175x164.png/5fa2dd/ffffff'
// }, {
//   id: 20,
//   name: 'Riffpath',
//   address: '9438 3rd Parkway',
//   user_count: 20,
//   image: 'http://dummyimage.com/168x108.bmp/cc0000/ffffff'
// }, {
//   id: 21,
//   name: 'Mynte',
//   address: '02 Milwaukee Court',
//   user_count: 21,
//   image: 'http://dummyimage.com/234x135.bmp/5fa2dd/ffffff'
// }, {
//   id: 22,
//   name: 'Jaloo',
//   address: '601 Heffernan Lane',
//   user_count: 22,
//   image: 'http://dummyimage.com/180x162.jpg/5fa2dd/ffffff'
// }, {
//   id: 23,
//   name: 'InnoZ',
//   address: '778 Walton Lane',
//   user_count: 23,
//   image: 'http://dummyimage.com/208x145.png/cc0000/ffffff'
// }, {
//   id: 24,
//   name: 'Yozio',
//   address: '1 Gulseth Terrace',
//   user_count: 24,
//   image: 'http://dummyimage.com/135x128.png/dddddd/000000'
// }, {
//   id: 25,
//   name: 'Tagtune',
//   address: '0 New Castle Point',
//   user_count: 25,
//   image: 'http://dummyimage.com/187x213.jpg/5fa2dd/ffffff'
// }, {
//   id: 26,
//   name: 'Browsebug',
//   address: '90 Delladonna Avenue',
//   user_count: 26,
//   image: 'http://dummyimage.com/244x229.bmp/5fa2dd/ffffff'
// }, {
//   id: 27,
//   name: 'Reallinks',
//   address: '2 Cascade Terrace',
//   user_count: 27,
//   image: 'http://dummyimage.com/120x159.bmp/dddddd/000000'
// }, {
//   id: 28,
//   name: 'Buzzster',
//   address: '32543 Hollow Ridge Court',
//   user_count: 28,
//   image: 'http://dummyimage.com/102x138.png/dddddd/000000'
// }, {
//   id: 29,
//   name: 'Kwinu',
//   address: '52 Trailsway Point',
//   user_count: 29,
//   image: 'http://dummyimage.com/107x120.bmp/dddddd/000000'
// }, {
//   id: 30,
//   name: 'Oba',
//   address: '0 Chinook Plaza',
//   user_count: 30,
//   image: 'http://dummyimage.com/238x116.bmp/cc0000/ffffff'
// }, {
//   id: 31,
//   name: 'Yakitri',
//   address: '307 Center Road',
//   user_count: 31,
//   image: 'http://dummyimage.com/122x221.jpg/cc0000/ffffff'
// }, {
//   id: 32,
//   name: 'Voolith',
//   address: '96639 Hintze Place',
//   user_count: 32,
//   image: 'http://dummyimage.com/119x167.png/5fa2dd/ffffff'
// }, {
//   id: 33,
//   name: 'Centizu',
//   address: '3 Walton Center',
//   user_count: 33,
//   image: 'http://dummyimage.com/141x110.jpg/dddddd/000000'
// }, {
//   id: 34,
//   name: 'Oyoba',
//   address: '441 Lawn Center',
//   user_count: 34,
//   image: 'http://dummyimage.com/142x240.jpg/ff4444/ffffff'
// }, {
//   id: 35,
//   name: 'Trudoo',
//   address: '319 Mallard Road',
//   user_count: 35,
//   image: 'http://dummyimage.com/131x123.png/ff4444/ffffff'
// }, {
//   id: 36,
//   name: 'Pixonyx',
//   address: '55207 Debra Point',
//   user_count: 36,
//   image: 'http://dummyimage.com/165x139.jpg/ff4444/ffffff'
// }, {
//   id: 37,
//   name: 'Youopia',
//   address: '08 Daystar Alley',
//   user_count: 37,
//   image: 'http://dummyimage.com/175x122.png/cc0000/ffffff'
// }, {
//   id: 38,
//   name: 'Lajo',
//   address: '1 Graceland Point',
//   user_count: 38,
//   image: 'http://dummyimage.com/211x158.bmp/5fa2dd/ffffff'
// }, {
//   id: 39,
//   name: 'Babbleopia',
//   address: '73300 Mcbride Hill',
//   user_count: 39,
//   image: 'http://dummyimage.com/195x169.jpg/cc0000/ffffff'
// }, {
//   id: 40,
//   name: 'Skimia',
//   address: '8200 Atwood Hill',
//   user_count: 40,
//   image: 'http://dummyimage.com/184x143.png/5fa2dd/ffffff'
// }, {
//   id: 41,
//   name: 'Skipstorm',
//   address: '01685 Kipling Park',
//   user_count: 41,
//   image: 'http://dummyimage.com/224x250.jpg/dddddd/000000'
// }, {
//   id: 42,
//   name: 'Rhynyx',
//   address: '70830 Dennis Crossing',
//   user_count: 42,
//   image: 'http://dummyimage.com/139x162.png/5fa2dd/ffffff'
// }, {
//   id: 43,
//   name: 'Skippad',
//   address: '25998 7th Court',
//   user_count: 43,
//   image: 'http://dummyimage.com/208x238.bmp/5fa2dd/ffffff'
// }, {
//   id: 44,
//   name: 'DabZ',
//   address: '117 Elmside Trail',
//   user_count: 44,
//   image: 'http://dummyimage.com/108x219.bmp/dddddd/000000'
// }, {
//   id: 45,
//   name: 'Oyoba',
//   address: '63934 Lunder Center',
//   user_count: 45,
//   image: 'http://dummyimage.com/202x188.jpg/5fa2dd/ffffff'
// }, {
//   id: 46,
//   name: 'Aivee',
//   address: '96373 Coleman Trail',
//   user_count: 46,
//   image: 'http://dummyimage.com/179x111.png/dddddd/000000'
// }, {
//   id: 47,
//   name: 'Demimbu',
//   address: '18405 Roxbury Way',
//   user_count: 47,
//   image: 'http://dummyimage.com/126x240.png/ff4444/ffffff'
// }, {
//   id: 48,
//   name: 'Dazzlesphere',
//   address: '326 Roxbury Alley',
//   user_count: 48,
//   image: 'http://dummyimage.com/220x209.jpg/cc0000/ffffff'
// }, {
//   id: 49,
//   name: 'Devify',
//   address: '02610 Grayhawk Junction',
//   user_count: 49,
//   image: 'http://dummyimage.com/126x140.png/cc0000/ffffff'
// }, {
//   id: 50,
//   name: 'Browsetype',
//   address: '205 Nancy Avenue',
//   user_count: 50,
//   image: 'http://dummyimage.com/183x118.png/cc0000/ffffff'
// }, {
//   id: 51,
//   name: 'Blogpad',
//   address: '398 Garrison Road',
//   user_count: 51,
//   image: 'http://dummyimage.com/184x174.jpg/5fa2dd/ffffff'
// }, {
//   id: 52,
//   name: 'Pixoboo',
//   address: '3965 Anhalt Circle',
//   user_count: 52,
//   image: 'http://dummyimage.com/118x189.jpg/ff4444/ffffff'
// }, {
//   id: 53,
//   name: 'Voonte',
//   address: '4497 Hudson Trail',
//   user_count: 53,
//   image: 'http://dummyimage.com/124x194.png/ff4444/ffffff'
// }, {
//   id: 54,
//   name: 'Edgeclub',
//   address: '8364 Mayfield Hill',
//   user_count: 54,
//   image: 'http://dummyimage.com/147x238.png/ff4444/ffffff'
// }, {
//   id: 55,
//   name: 'Wikizz',
//   address: '852 Norway Maple Plaza',
//   user_count: 55,
//   image: 'http://dummyimage.com/241x182.png/cc0000/ffffff'
// }, {
//   id: 56,
//   name: 'Skinte',
//   address: '1489 Glacier Hill Lane',
//   user_count: 56,
//   image: 'http://dummyimage.com/220x198.jpg/dddddd/000000'
// }, {
//   id: 57,
//   name: 'Feedbug',
//   address: '51 Killdeer Avenue',
//   user_count: 57,
//   image: 'http://dummyimage.com/144x188.jpg/ff4444/ffffff'
// }, {
//   id: 58,
//   name: 'Mymm',
//   address: '5 Corry Parkway',
//   user_count: 58,
//   image: 'http://dummyimage.com/165x118.bmp/dddddd/000000'
// }, {
//   id: 59,
//   name: 'Jatri',
//   address: '2214 Dryden Circle',
//   user_count: 59,
//   image: 'http://dummyimage.com/140x161.bmp/cc0000/ffffff'
// }, {
//   id: 60,
//   name: 'Riffpath',
//   address: '78 Moulton Trail',
//   user_count: 60,
//   image: 'http://dummyimage.com/225x152.png/5fa2dd/ffffff'
// }, {
//   id: 61,
//   name: 'Zooxo',
//   address: '95 Weeping Birch Street',
//   user_count: 61,
//   image: 'http://dummyimage.com/174x249.bmp/5fa2dd/ffffff'
// }, {
//   id: 62,
//   name: 'Kwideo',
//   address: '57 Porter Circle',
//   user_count: 62,
//   image: 'http://dummyimage.com/198x183.png/cc0000/ffffff'
// }, {
//   id: 63,
//   name: 'Tambee',
//   address: '37 Stuart Trail',
//   user_count: 63,
//   image: 'http://dummyimage.com/208x194.bmp/cc0000/ffffff'
// }, {
//   id: 64,
//   name: 'Skimia',
//   address: '9 Texas Junction',
//   user_count: 64,
//   image: 'http://dummyimage.com/111x153.jpg/5fa2dd/ffffff'
// }, {
//   id: 65,
//   name: 'Mymm',
//   address: '094 Barby Road',
//   user_count: 65,
//   image: 'http://dummyimage.com/234x221.jpg/cc0000/ffffff'
// }, {
//   id: 66,
//   name: 'Dynabox',
//   address: '17 Sugar Pass',
//   user_count: 66,
//   image: 'http://dummyimage.com/188x232.png/5fa2dd/ffffff'
// }, {
//   id: 67,
//   name: 'Trudoo',
//   address: '171 Lakewood Hill',
//   user_count: 67,
//   image: 'http://dummyimage.com/111x100.jpg/5fa2dd/ffffff'
// }, {
//   id: 68,
//   name: 'Aibox',
//   address: '3529 Fordem Way',
//   user_count: 68,
//   image: 'http://dummyimage.com/205x107.jpg/cc0000/ffffff'
// }, {
//   id: 69,
//   name: 'Divape',
//   address: '4 North Parkway',
//   user_count: 69,
//   image: 'http://dummyimage.com/170x162.bmp/ff4444/ffffff'
// }, {
//   id: 70,
//   name: 'Thoughtsphere',
//   address: '33 Sloan Way',
//   user_count: 70,
//   image: 'http://dummyimage.com/135x226.png/dddddd/000000'
// }, {
//   id: 71,
//   name: 'Skimia',
//   address: '3 Macpherson Hill',
//   user_count: 71,
//   image: 'http://dummyimage.com/122x171.bmp/dddddd/000000'
// }, {
//   id: 72,
//   name: 'Jazzy',
//   address: '19226 Village Green Court',
//   user_count: 72,
//   image: 'http://dummyimage.com/118x100.png/cc0000/ffffff'
// }, {
//   id: 73,
//   name: 'Buzzshare',
//   address: '2 Straubel Crossing',
//   user_count: 73,
//   image: 'http://dummyimage.com/220x172.bmp/ff4444/ffffff'
// }, {
//   id: 74,
//   name: 'Meevee',
//   address: '832 Homewood Point',
//   user_count: 74,
//   image: 'http://dummyimage.com/177x154.jpg/ff4444/ffffff'
// }, {
//   id: 75,
//   name: 'Pixope',
//   address: '16325 Portage Drive',
//   user_count: 75,
//   image: 'http://dummyimage.com/117x176.png/dddddd/000000'
// }, {
//   id: 76,
//   name: 'Tazzy',
//   address: '5368 Westport Place',
//   user_count: 76,
//   image: 'http://dummyimage.com/125x181.bmp/ff4444/ffffff'
// }, {
//   id: 77,
//   name: 'Brightbean',
//   address: '2971 Northridge Street',
//   user_count: 77,
//   image: 'http://dummyimage.com/134x105.jpg/ff4444/ffffff'
// }, {
//   id: 78,
//   name: 'Wikibox',
//   address: '58 Quincy Point',
//   user_count: 78,
//   image: 'http://dummyimage.com/116x157.png/ff4444/ffffff'
// }, {
//   id: 79,
//   name: 'Bluejam',
//   address: '3 Buena Vista Point',
//   user_count: 79,
//   image: 'http://dummyimage.com/249x168.jpg/dddddd/000000'
// }, {
//   id: 80,
//   name: 'Meejo',
//   address: '66 Dryden Terrace',
//   user_count: 80,
//   image: 'http://dummyimage.com/217x187.png/ff4444/ffffff'
// }, {
//   id: 81,
//   name: 'Reallinks',
//   address: '63 Caliangt Pass',
//   user_count: 81,
//   image: 'http://dummyimage.com/106x196.png/5fa2dd/ffffff'
// }, {
//   id: 82,
//   name: 'Quimba',
//   address: '94079 Vera Way',
//   user_count: 82,
//   image: 'http://dummyimage.com/225x229.png/cc0000/ffffff'
// }, {
//   id: 83,
//   name: 'Tazzy',
//   address: '406 Jay Court',
//   user_count: 83,
//   image: 'http://dummyimage.com/229x109.png/5fa2dd/ffffff'
// }, {
//   id: 84,
//   name: 'Quinu',
//   address: '05 Manufacturers Road',
//   user_count: 84,
//   image: 'http://dummyimage.com/185x213.bmp/cc0000/ffffff'
// }, {
//   id: 85,
//   name: 'InnoZ',
//   address: '52310 Evergreen Street',
//   user_count: 85,
//   image: 'http://dummyimage.com/246x135.bmp/ff4444/ffffff'
// }, {
//   id: 86,
//   name: 'Brainbox',
//   address: '253 Ridge Oak Point',
//   user_count: 86,
//   image: 'http://dummyimage.com/226x240.bmp/ff4444/ffffff'
// }, {
//   id: 87,
//   name: 'Linkbridge',
//   address: '38 School Court',
//   user_count: 87,
//   image: 'http://dummyimage.com/238x182.png/dddddd/000000'
// }, {
//   id: 88,
//   name: 'Thoughtsphere',
//   address: '626 Commercial Hill',
//   user_count: 88,
//   image: 'http://dummyimage.com/164x217.bmp/cc0000/ffffff'
// }, {
//   id: 89,
//   name: 'Bubbletube',
//   address: '82809 Anniversary Junction',
//   user_count: 89,
//   image: 'http://dummyimage.com/239x186.jpg/dddddd/000000'
// }, {
//   id: 90,
//   name: 'Shufflester',
//   address: '083 Pleasure Plaza',
//   user_count: 90,
//   image: 'http://dummyimage.com/163x231.png/dddddd/000000'
// }, {
//   id: 91,
//   name: 'Twimbo',
//   address: '1 Amoth Avenue',
//   user_count: 91,
//   image: 'http://dummyimage.com/196x208.png/cc0000/ffffff'
// }, {
//   id: 92,
//   name: 'Tagchat',
//   address: '32031 Fremont Junction',
//   user_count: 92,
//   image: 'http://dummyimage.com/173x241.bmp/5fa2dd/ffffff'
// }, {
//   id: 93,
//   name: 'Browseblab',
//   address: '32 Bluejay Street',
//   user_count: 93,
//   image: 'http://dummyimage.com/117x132.jpg/cc0000/ffffff'
// }, {
//   id: 94,
//   name: 'Flashspan',
//   address: '7252 American Point',
//   user_count: 94,
//   image: 'http://dummyimage.com/216x102.jpg/dddddd/000000'
// }, {
//   id: 95,
//   name: 'Oozz',
//   address: '6041 Pearson Pass',
//   user_count: 95,
//   image: 'http://dummyimage.com/244x103.jpg/ff4444/ffffff'
// }, {
//   id: 96,
//   name: 'Edgeclub',
//   address: '8594 Mcguire Terrace',
//   user_count: 96,
//   image: 'http://dummyimage.com/217x194.bmp/cc0000/ffffff'
// }, {
//   id: 97,
//   name: 'Aivee',
//   address: '8081 Heath Junction',
//   user_count: 97,
//   image: 'http://dummyimage.com/138x155.bmp/5fa2dd/ffffff'
// }, {
//   id: 98,
//   name: 'Plambee',
//   address: '23948 Meadow Vale Point',
//   user_count: 98,
//   image: 'http://dummyimage.com/241x152.png/cc0000/ffffff'
// }, {
//   id: 99,
//   name: 'Dabshots',
//   address: '615 Anniversary Street',
//   user_count: 99,
//   image: 'http://dummyimage.com/107x103.png/dddddd/000000'
// }, {
//   id: 100,
//   name: 'Vinte',
//   address: '8 Mitchell Way',
//   user_count: 100,
//   image: 'http://dummyimage.com/121x137.bmp/cc0000/ffffff'
// }]

export const fetchProperties = () => async (dispatch, getState, api) => {
  dispatch({ type: FETCH_PROPERTIES })
  try {
    const res = await api.get(`properties`)
    if (res.data.success === true) {
      dispatch({
        type: FETCH_PROPERTIES_SUCCESS,
        payload: res.data.data
      })
    } else {
      dispatch({ type: FETCH_PROPERTIES_SUCCESS })
    }
  } catch (error) {
    console.warn(error)
    dispatch({ type: FETCH_PROPERTIES_SUCCESS })
  }
}
