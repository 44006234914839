import React, { PureComponent, Fragment } from 'react'
// import PropTypes from 'prop-types'
import './ManageUser.sass'

import Loader from 'react-loaders'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { fetchManageUser, postManageUser, deleteManageUser, resetManageUser } from '../../actions'

import DropZone from '../../components/DropZone/index'
import PageTitle from '../../components/PageTitle/PageTitle'
import UserGroupModal from '../../components/UserGroupModal/UserGroupModal'

import {
  Row, Col, Card, CardBody, Button, Form, FormGroup, Label, CustomInput, Input
} from 'reactstrap'

class ManageUser extends PureComponent {
  state={
    name: '',
    id: '',
    email: '',
    group_id: '',
    role: '',
    image: '',
    status: '',
    group_name: ''
  }

  componentDidMount () {
    if (this.props.match.params.id !== undefined) {
      this.props.fetchManageUser(this.props.match.params.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.manageUser.data !== this.props.manageUser.data) {
      this._updateState()
    }
  }

  componentWillUnmount = () => {
    this.props.resetManageUser()
  }

  _updateState = () => {
    this.setState({
      id: this.props.manageUser.data.id,
      name: this.props.manageUser.data.name,
      image: this.props.manageUser.data.image,
      email: this.props.manageUser.data.email,
      group_id: this.props.manageUser.data.group_id,
      role: this.props.manageUser.data.role,
      status: this.props.manageUser.data.status,
      group_name: this.props.manageUser.data.group_name
    })
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleCancell = (e) => {
    e.preventDefault()
    return this.props.history.push(`/manage_users`)
  }

  _handleImage = (file, image_url) => {
    this.setState({ image: file, image_url })
  }

  _handleSubmit = (e) => {
    e.preventDefault()
    // prepare data object
    const data = {
      name: this.state.name,
      email: this.state.email,
      group_id: this.state.group_id,
      role: this.state.role
    }
    if (this.state.id) {
      data.id = this.state.id
    }
    const image = this.state.image

    // validate ?

    // prepare success function
    const success = () => {
      toast.success(
        '保存が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      this.setState({
        name: '',
        id: '',
        email: '',
        group_id: '',
        role: '',
        image: '',
        status: '',
        group_name: ''
      })
      return this.props.history.push(`/manage_users`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '保存に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.log(message)
    }

    // send data to action
    this.props.postManageUser(data, image, success, fail)
  }

  _handleDelete = (e) => {
    e.preventDefault()
    // validate ?

    // prepare success function
    const success = () => {
      toast.success(
        '削除が完了しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      return this.props.history.push(`/manage_users`)
    }
    // prepare fail function
    const fail = (message) => {
      toast.warn(
        '削除に失敗しました。',
        {
          autoClose: 3000,
          hideProgressBar: false
        }
      )
      console.log(message)
    }
    const FinalAnswer = window.confirm('本当に"' + this.state.name + '" を削除しますか')
    if (FinalAnswer) {
      this.props.deleteManageUser(this.props.match.params.id, success, fail)
    }
  }

  _handleUserChange = (group, e) => {
    // const { group_id } = this.state
    const checked = e.target.checked
    // console.log('group', group, 'group_id', group_id)
    // if (!members.includes(id)) {
    //   this.setState({ members: [...members, id] })
    // // }
    // console.log('user', user)
    if (checked) {
      this.setState({ group_id: group.id })
      this.setState({ group_name: group.name })
    } else {
      this.setState({ group_id: '' })
      this.setState({ group_name: '' })
    }

    // console.log('members:', members)
    // console.log('changestate', this.state)
  }

  _radioChange= (e) => {
    console.log(typeof e.target.id)
    switch (e.target.id) {
      case '0':
        this.setState({ role: 0 })
        break
      case '1':
        this.setState({ role: 1 })
        break
      default:
        this.setState({ role: 2 })
        break
    }
  }

  _rednerPageTitle = () => {
    const { name } = this.state
    return (
      <PageTitle
        heading={name}
        icon="pe-7s-key stroke-orange"
      />
    )
  }

  _handleImageDelete = () => {
    this.setState({
      image_url: '',
      image: ''
    })
  }

  _renderDeleteBtn = () => {
    if (!this.props.manageUser.deleting) {
      return <Button color="danger" className="mt-2" type="button" onClick={this._handleDelete}>削除</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderSubmitBtn = () => {
    if (!this.props.manageUser.posting) {
      return <Button color="success" type="submit" className="mt-2">保存</Button>
    } else {
      return <Button type="button" className="mt-2">送信中</Button>
    }
  }

  _renderBtn = () => {
    if (this.props.role === 1) return null
    if (this.props.match.params.id !== undefined) {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 3 }}>
            <Button outline className=" mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
          <Col md={2}>
            {this._renderDeleteBtn()}
          </Col>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Col md={{ size: 2, offset: 4 }}>
            <Button outline className="mt-2 border-0 btn-transition" color="warning" type="button" onClick={this._handleCancell}>キャンセル</Button>
          </Col>
          <Col md={2}>
            {this._renderSubmitBtn()}
          </Col>
        </Fragment>
      )
    }
  }

  _renderForm = () => {
    const {
      name,
      email,
      role,
      image,
      image_url,
      group_name
    } = this.state
    return (
      <Form onSubmit={this._handleSubmit}>
        <FormGroup>
          <Label for="title">名前<span className="required">*</span></Label>
          <Input type="text" name="name" id="name" value={name} onChange={this._handleChange} required/>
        </FormGroup>
        <FormGroup>
          <Label for="content">メールアドレス</Label>
          <Input type="email" name="email" id="email" value={email} onChange={this._handleChange} />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>属性</Label>
              <Row>
                <Col>
                  <CustomInput type="radio" id="0" name="role" checked={role === 0} onChange={this._radioChange} label="管理者" />
                </Col>
                <Col>
                  <CustomInput type="radio" id="1" name="role" checked={role === 1} onChange={this._radioChange} label="編集者" />
                </Col>
                <Col>
                  <CustomInput type="radio" id="2" name="role" checked={role === 2} onChange={this._radioChange} label="閲覧者" />
                </Col>
              </Row>
            </FormGroup>
            {
              this.state.role !== 0
                ? (
                  <Fragment>
                    <FormGroup>
                      <Label>グループ</Label>
                      <div>{group_name || ''}</div>
                    </FormGroup>
                    <UserGroupModal
                      modalTitle='グループを選択する'
                      modalContent='test'
                      handleCheck={this._handleUserChange}
                      members={this.state.group_id}
                    />
                  </Fragment>
                )
                : null
            }
          </Col>
          <Col>
            <Label for="image_url">画像</Label>
            <DropZone
              files={image}
              imageUrl={image_url} // pass image URL or base64 for preview
              handleChange={this._handleImage}
              imagePropsUrl={this.props.manageUser.data ? this.props.manageUser.data.image : ''}
              reset={this._handleImageDelete}
            />
          </Col>
        </Row>
        <Row>
          {this._renderBtn()}
        </Row>
      </Form>
    )
  }

  render () {
    const { data, loading } = this.props.manageUser
    if (this.props.match.params.id !== undefined) {
      if (loading) {
        return (
          <div className="table_center maxHeight">
            <Loader type="ball-pulse" />
          </div>
        )
      }
      if (!data) return null
    }
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            {this._rednerPageTitle()}
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <p><span className="required">*</span>は必須項目です。</p>
                  {this._renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ manageUser, auth }) => ({
  manageUser,
  role: auth.user.role
})

const mapDispatchToProps = {
  fetchManageUser, postManageUser, deleteManageUser, resetManageUser
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageUser)

ManageUser.propTypes = {

}
ManageUser.defaultProps = {

}
