/* eslint-disable */
import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, CustomInput, Form } from 'reactstrap'
import _ from 'lodash'
import { fetchManageGroups } from '../../actions'
import { connect } from 'react-redux'
import ModalItem from '../ModalItem/ModalItem'
import Loader from 'react-loaders'

class UserGroupModal extends PureComponent {
  state = {
    modal: false
  }

  componentDidMount = () => {
    this.props.fetchManageGroups()
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  _createData = (data) => {
    const sorted = {}
    data.forEach(item => {
      const {
        id,
        name,
      } = item
      sorted[id] = {
        id,
        name
      }
    })
    return sorted
  }

  _renderItems = () => {
    const { manageGroups, handleCheck, members } = this.props
    if (manageGroups.loading) {
      return <div className="table_center">
        <Loader type="ball-pulse" />
      </div>
    }
    if (!manageGroups.data) return null
    // console.log('residentsdata:', this.props.manageUsers.data)
    // console.log(this._createData(manageUsers.data))
    return (
      _.map(this._createData(manageGroups.data), (value, name) => {
        return (
          <Fragment key={name}>
            <CustomInput className="ml-4" type="checkbox" id={`Users_${name}`} label={value.name} onChange={(e) => handleCheck(value, e)} checked={members===value.id} />
          </Fragment>
        )
      })
    )
  }

  render() {
    return (
      <span className="d-block mb-2">
        <Button color="primary" onClick={this.toggle}>{this.props.modalTitle}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                {this._renderItems()}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>閉じる</Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = ({ manageGroups }) => ({
  manageGroups
})

const mapDispatchToProps = {
  fetchManageGroups
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupModal)
