import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import './Notices.sass'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import moment from 'moment'
import Loader from 'react-loaders'

import { fetchNotices } from '../../actions'

import {
  Row, Col,
  Card, CardBody,
  Button
} from 'reactstrap'
import {
  PREVIOUS_TEXT,
  NEXT_TEXT,
  LOADING_TEXT,
  NO_DATA_TEXT,
  PAGE_TEXT,
  OF_TEXT,
  ROWS_TEXT
} from '../../constants'

import ReactTable from 'react-table'

import PageTitle from '../../components/PageTitle/PageTitle'
import StatusModal from './StatusModal'

class Notices extends PureComponent {
  state = {
    showStatusModal: false,
    selectedNotice: null
  }

  componentDidMount = () => {
    this.props.fetchNotices()
  }

  _clickAction = (e) => {
    return this.props.history.push(`/notices/add`)
  }

  _handleSelectClick = (e, id) => {
    e.stopPropagation()
    this.setState({ selectedNotice: id, showStatusModal: true })
  }

  _handleCloseStatusModal = () => {
    this.setState({ selectedNotice: null, showStatusModal: false })
  }

  _renderStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-success"> success</div>
            <div>配信済み</div>
          </div>
        )
      case 2:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-info"> Info</div>
            <div>配信予約</div>
          </div>
        )
      case 3:
        return (
          <div className="flex_center">
            <div className=" mr-2 badge badge-dot badge-dot-m badge-warning"> warning</div>
            <div>配信予約</div>
          </div>
        )
      default:
        return null
    }
  }

  _renderConfirmReadStatus = (push_type, id) => {
    if (push_type === 0) return null
    return (
      <Button color="success" className="mt-2" onClick={(e) => this._handleSelectClick(e, id)}>状況確認する</Button>
    )
  }

  _renderTable = () => {
    if (this.props.notices.loading) {
      return (
        <div className="table_center">
          <Loader type="ball-pulse" />
        </div> // TODO: add a real loader?
      )
    }

    if (!this.props.notices.data) return null
    return (
      <ReactTable
        data={this.props.notices.data}
        defaultPageSize={10}
        getTrProps={(state, rowInfo, columns) => {
          return {
            onClick: (e) => {
              if (rowInfo.original.id !== null) {
                return this.props.history.push(`/notices/${rowInfo.original.id}`)
              }
            }
          }
        }}
        columns={[
          {
            Header: 'タイトル',
            accessor: 'title'
          },
          {
            className: 'table_center',
            Header: '日付',
            accessor: 'date',
            maxWidth: 100,
            Cell: row => (
              <Fragment>
                {row.value ? moment(new Date(row.value)).format('YYYY/MM/DD') : ''}
              </Fragment>
            )
          },
          {
            Header: 'ステータス',
            accessor: 'status',
            maxWidth: 120,
            Cell: row => (
              <Fragment>
                {this._renderStatus(row.value)}
              </Fragment>
            )
          },
          {
            Header: '開封状況',
            accessor: 'push_type',
            maxWidth: 120,
            Cell: row => (
              <Fragment>
                {this._renderConfirmReadStatus(row.value, row.original.id)}
              </Fragment>
            )
          }
        ]}
        previousText={PREVIOUS_TEXT}
        nextText={NEXT_TEXT}
        loadingText={LOADING_TEXT}
        noDataText={NO_DATA_TEXT}
        pageText={PAGE_TEXT}
        ofText={OF_TEXT}
        rowsText={ROWS_TEXT}
      />
    )
  }

  render () {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.state.showStatusModal ? <StatusModal id={this.state.selectedNotice} closeModal={this._handleCloseStatusModal} /> : null }
          <div>
            <PageTitle
              heading="お知らせ一覧"
              icon="pe-7s-bell stroke-orange"
              btnSupportedRoles={[0, 1]}
              btnClick = {this._clickAction}
              btnText="追加"
              btnColor="warning"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {this._renderTable()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>

    )
  }
}

const mapStateToProps = ({ notices }) => ({
  notices
})

const mapDispatchToProps = {
  fetchNotices
}

export default connect(mapStateToProps, mapDispatchToProps)(Notices)

Notices.propTypes = {
  fetchNotices: PropTypes.func,
  notice: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      data: PropTypes.string,
      status: PropTypes.oneOf([0, 1, 2])
    }))
  })
}
Notices.defaultProps = {

}
