import React, { PureComponent } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class RoleRoute extends PureComponent {
  render () {
    const { supportedRoles, exceptedRoles, path, component, userRole, ...rest } = this.props
    // console.log('userRole', userRole, typeof userRole)
    // console.log('supportedRoles', supportedRoles)
    // console.log('exceptedRoles', exceptedRoles)
    if (supportedRoles) {
      // console.log('included', supportedRoles.includes(userRole))
      if (!supportedRoles.includes(userRole)) return <Redirect to="/404" />
      return <Route path={path} component={component} {...rest}/>
    }

    if (exceptedRoles) {
      if (exceptedRoles.includes(userRole)) return <Redirect to="/404" />
      return <Route path={path} component={component} {...rest}/>
    }
    return null
  }
}

function mapStateToProps ({ auth }) {
  return { userRole: auth.user.role }
}

export default connect(mapStateToProps, null)(RoleRoute)

RoleRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  userRole: PropTypes.number.isRequired,
  supportedRoles: (props, propName, componentName) => {
    if (props.supportedRoles) {
      if (Array.isArray(props.supportedRoles)) {
        if (!props.supportedRoles.every((val) => Number.isInteger(val))) {
          return new Error(`The props '${propName}' must be an array of Numbers in '${componentName}'.`)
        }
      } else {
        return new Error(`The props '${propName}' must be an array in '${componentName}'.`)
      }
    }
    if (!props.supportedRoles && !props.exceptedRoles) {
      return new Error(`One of props 'supportedRoles' or 'exceptedRoles' was not specified in '${componentName}'.`)
    }
  },
  exceptedRoles: (props, propName, componentName) => {
    if (props.exceptedRoles) {
      if (Array.isArray(props.exceptedRoles)) {
        if (!props.exceptedRoles.every((val) => Number.isInteger(val))) {
          return new Error(`The props '${propName}' must be an array of Numbers in '${componentName}'.`)
        }
      } else {
        return new Error(`The props '${propName}' must be an array in '${componentName}'.`)
      }
    }
    if (!props.supportedRoles && !props.exceptedRoles) {
      return new Error(`One of props 'supportedRoles' or 'exceptedRoles' was not specified in '${componentName}'.`)
    }
  }
}
