import {
  FETCH_INFORMATION,
  FETCH_INFORMATION_SUCCESS,
  FETCH_INFORMATION_FAIL,
  POST_INFORMATION,
  POST_INFORMATION_SUCCESS,
  POST_INFORMATION_FAIL,
  DELETE_INFORMATION,
  DELETE_INFORMATION_SUCCESS,
  DELETE_INFORMATION_FAIL,
  RESET_INFORMATION,
  FETCH_INFORMATION_TAGS,
  FETCH_INFORMATION_TAGS_SUCCESS,
  FETCH_INFORMATION_TAGS_FAIL
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  loadingTags: true,
  posting: false,
  deleting: false,
  data: null,
  tags: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INFORMATION:
      return { ...state, loading: true }
    case FETCH_INFORMATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case FETCH_INFORMATION_FAIL:
      return { ...state, loading: false }
    case FETCH_INFORMATION_TAGS:
      return { ...state, loadingTags: true }
    case FETCH_INFORMATION_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        loadingTags: false
      }
    case FETCH_INFORMATION_TAGS_FAIL:
      return { ...state, loadingTags: false }
    case POST_INFORMATION:
      return { ...state, posting: true, deleting: true }
    case POST_INFORMATION_SUCCESS:
      return { ...state, posting: false, deleting: false }
    case POST_INFORMATION_FAIL:
      return { ...state, posting: false, deleting: false }
    case DELETE_INFORMATION:
      return { ...state, posting: true, deleting: true }
    case DELETE_INFORMATION_SUCCESS:
      return { ...state, posting: false, deleting: false }
    case DELETE_INFORMATION_FAIL:
      return { ...state, posting: false, deleting: false }
    case RESET_INFORMATION:
      return INITIAL_STATE
    default:
      return (state)
  }
}
