import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER
} from '../actions/types'

const INITIAL_STATE = {
  loading: null,
  token: null,
  user: null,
  error: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true }
    case LOGIN_USER_SUCCESS:
      const { token, user } = action.payload
      return { ...state, loading: false, token, user }
    case LOGIN_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case LOGOUT_USER:
      return INITIAL_STATE
    default: return { ...state }
  }
}
